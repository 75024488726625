import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import MyPager from "../components/MyPager";
import MyCalculation from "../utils/MyCalculation";

export default class Portuguese {
  constructor() {
    console.log("Português: construtor");
  }
  static content = {
    site: {
      loading_message: "Carregando...",
      ie_message: (
        <div className="lead">
          Parece que você está usando Internet Explorer (IE). Nosso site
          funciona melhor com o Chrome e talvez não possamos oferecer suporte ao
          IE no futuro. Considere usar o Chrome, Edge ou Firefox para obter os
          melhores resultados.
          <br />
          <br />
          Você pode baixar o Chrome aqui:{" "}
          <a
            target="_blank"
            href="https://www.google.com/chrome/"
            rel="noopener noreferrer"
          >
            Chrome
          </a>
        </div>
      ),
      share_button_label: {
        share: "Compartilhar esta página",
        hide: "Ocultar opções de compartilhamento",
      },
    },
    benefitHarmPage: {
      banner_image_source: "/image/FLIKR+Yale+Rosen+CC2.0.jpg",
      banner_title: "Triagem de Câncer de Pulmão",
      title: "Coisas que você deve saber sobre a triagem de câncer de pulmão",
      benefit_harm_content: {
        benefit: {
          title: "QUAIS SÃO OS BENEFÍCIOS DA TRIAGEM?",
          content:
            "Para pessoas que são elegíveis para a triagem e decidem se submeter ao exame, as chances de encontrar o câncer precocemente são maiores. Encontrar o câncer em estágio inicial geralmente significa que podem ser oferecidos tratamentos mais bem-sucedidos. Um estudo recente mostrou que depois de 6,5 anos, entre aqueles elegíveis para a triagem, aqueles que realizaram a tomografia estavam 20% menos propensos a morrer de câncer de pulmão em comparação com aqueles que não realizaram a tomografia.",
        },
        harm: {
          title: "QUAIS SÃO OS DANOS DA TRIAGEM?",
          content: (
            <div>
              <p>
                A triagem do câncer de pulmão só ajuda a encontrar o câncer se
                ele já existir. Ela não pode prevenir o câncer. A melhor forma
                de prevenir o câncer é parar de fumar, caso ainda não tenha
                feito isso. A tomografia computadorizada para triagem de câncer
                de pulmão usa uma dose menor de radiação em comparação com uma
                tomografia convencional de tórax: até 90% menos. Essa dose de
                radiação equivale a cerca de 6 meses de exposição à radiação do
                dia a dia. O risco adicional de desenvolver um câncer de pulmão
                fatal a partir de um único exame em um adulto é considerado
                extremamente baixo. Para aqueles interessados na dose total de
                radiação, você pode visitar&nbsp;
                <a
                  target="_blank"
                  href="http://www.xrayrisk.com/calculator/calculator-normal-studies.php?id=68"
                  rel="noopener noreferrer"
                >
                  este site
                </a>
                . Outros danos potenciais da triagem incluem: alarmes falsos,
                tratamento excessivo, mais exames e procedimentos invasivos.
              </p>
            </div>
          ),
        },
      },
      sample_list: [
        {
          title: "ALARMES FALSOS",
          content: (
            <div>
              <p>
                A triagem funciona encontrando nódulos pulmonares, alguns dos
                quais são câncer, mas a maioria dos nódulos detectados na
                tomografia computadorizada de baixa dose para triagem de câncer
                de pulmão <strong>não</strong> são câncer. Um nódulo pulmonar é
                um ponto redondo ou oval. Com as diretrizes atuais de manejo de
                nódulos, a taxa de falsos positivos para câncer de pulmão é
                baixa, cerca de 10-12% (
                <a
                  target="_blank"
                  href="https://pubmed.ncbi.nlm.nih.gov/29632062/"
                  rel="noopener noreferrer"
                >
                  McKee et al.
                </a>
                ). Resultados falso-positivos podem causar ansiedade.
                Entretanto, entender que a maioria dos nódulos pulmonares
                encontrados na triagem <strong>não </strong> representam câncer
                ajuda a maioria das pessoas a evitar uma ansiedade significativa
                enquanto aguardam exames de acompanhamento.
              </p>
            </div>
          ),
        },
        {
          title: "TRATAMENTO EXCESSIVO (OU SUPERDIAGNÓSTICO)",
          content: (
            <div>
              <p>
                Às vezes, a triagem encontra um nódulo que é câncer, mas que
                nunca chegaria a causar problemas. Isso pode levar a tratamentos
                como cirurgia, quimioterapia e radioterapia que são
                desnecessários e considerados tratamentos excessivos.
                Tratamentos excessivos podem causar efeitos colaterais que fazem
                você se sentir mal.
              </p>
            </div>
          ),
        },
        {
          title: "MAIS EXAMES",
          content: (
            <div>
              <p>
                Para comprovar que um nódulo não é câncer, geralmente a
                recomendação adequada é realizar exames de acompanhamento
                adicionais para monitorar mudanças. Seu médico fará
                recomendações sobre o intervalo de seguimento adequado para
                monitorar qualquer nódulo pulmonar detectado. Na maioria das
                vezes, esse intervalo é de 6 a 12 meses.
              </p>
            </div>
          ),
        },
        {
          title: "PROCEDIMENTOS INVASIVOS",
          content: (
            <div>
              <p>
                Às vezes, mais testes para investigar um achado na triagem de
                câncer de pulmão podem exigir procedimentos invasivos (biópsias
                ou cirurgia). Esses testes são arriscados e podem causar danos
                em pessoas que, ao final, não tinham câncer. Procedimentos
                invasivos podem resultar em complicações menores, como
                sangramento e infecções. Podem também resultar em complicações
                mais graves, como um pulmão colapsado e até mesmo morte.
              </p>
            </div>
          ),
        },
      ],
      more_info: (
        <p>
          Para mais detalhes, você pode visitar o{" "}
          <a
            href={"https://www.cancer.gov/types/lung/research/nlst-qa"}
            target="_blank"
            rel="noopener noreferrer"
          >
            {"Perguntas & Respostas"}
          </a>{" "}
          compilado pelo Instituto Nacional do Câncer (National Cancer
          Institute) que explica as descobertas do ensaio nacional de triagem de
          câncer de pulmão, ou o site{" "}
          <a
            href={"https://screenyourlungs.org/"}
            target="_blank"
            rel="noopener noreferrer"
          >
            {"screenyourlungs.org"}
          </a>
          .
        </p>
      ),
      image_info: {
        head: "Imagem: ",
        src: "https://www.flickr.com/photos/pulmonary_pathology/",
        text: "Yale Rosen/Flickr. CC BY-SA 2.0.",
      },
    },
    homePage: {
      banner_title: "Triagem de Câncer de Pulmão",
      banner_content: "Devo fazer a triagem?",
      banner_button_label: "Clique para saber mais",
      banner_button_link: "/Portuguese/como-e-realizado-o-rastreamento",
      two_column_title: "Nós podemos te ajudar.",
      two_column_sub_title:
        "Decidir se deve ou não fazer a tomografia de triagem para câncer de pulmão não é fácil. Aqui, há informações atualizadas fornecidas por médicos para ajudá-lo a tomar uma decisão informada.",
      two_column_content: {
        first: {
          title: "Quem é elegível para a triagem de câncer de pulmão?",
          sub_title: (
            <div>
              <p>
                A Força-Tarefa de Serviços Preventivos dos EUA (US Preventive
                Services Task Force) atualizou recentemente as diretrizes de
                elegibilidade para triagem de câncer de pulmão para o seguinte:
              </p>
              <ul>
                <li>50-80 anos de idade</li>
                <li>Ter fumado nos últimos 15 anos</li>
                <li>
                  20 anos-maço (veja{" "}
                  <Link
                    to={"/Portuguese/calculadora-de-anos-maco"}
                    style={{ width: "100%", textDecoration: "none" }}
                    data-on="click"
                    data-event-category="HomePage"
                    data-event-action={"/Portuguese/calculadora-de-anos-maco"}
                  >
                    calculadora de maços
                  </Link>
                  )
                </li>
              </ul>
              <p>
                As diretrizes de cobertura do Medicare e Medicaid para a triagem
                de câncer de pulmão (em 10 de fevereiro de 2022) são:
              </p>
              <ul>
                <li>50-77 anos de idade</li>
                <li>Ter fumado nos últimos 15 anos</li>
                <li>
                  20 anos-maço (veja{" "}
                  <Link
                    to={"/Portuguese/calculadora-de-anos-maco"}
                    style={{ width: "100%", textDecoration: "none" }}
                    data-on="click"
                    data-event-category="HomePage"
                    data-event-action={"/Portuguese/calculadora-de-anos-maco"}
                  >
                    calculadora de maços
                  </Link>
                  )
                </li>
              </ul>
            </div>
          ),
          image: "/image/questioningDude.jpg",
          button_label: "Saiba Mais",
          link: "/Portuguese/como-e-realizado-o-rastreamento",
        },
        second: {
          title: "Calculadora de Risco de Câncer de Pulmão",
          sub_title:
            "Você quer saber se deve ser triado? Use nossa calculadora para ver seu risco personalizado de câncer de pulmão.",
          image: "/image/SIScalculator.jpg",
          button_label: "Use agora",
          link: "/Portuguese/calculadora-de-risco-de-cancer-de-pulmao",
        },
      },
      time_navigation: [
        {
          title: "1. Por quê?",
          items: [
            {
              title: "Por que eu deveria estar pensando nisso?",
              content: (
                <div>
                  <p>
                    A triagem pode ajudar a encontrar o câncer de pulmão em um
                    estágio inicial, quando tratamentos mais bem-sucedidos podem
                    ser oferecidos. Sem a triagem, o câncer de pulmão geralmente
                    é descoberto em estágio avançado.
                  </p>
                  <div className="float-right">
                    <MyPager
                      link={"/Portuguese/como-e-realizado-o-rastreamento"}
                      label={"Saiba mais"}
                      arrowDirection={"right"}
                    ></MyPager>
                  </div>
                </div>
              ),
            },
            {
              title: "Quais são os sinais de alerta do câncer de pulmão?",
              content: (
                <p>
                  Sintomas como dor, perda de peso inesperada ou não
                  intencional, tossir sangue ou uma tosse persistente que não
                  desaparece são preocupantes para câncer de pulmão,
                  especialmente se você é fumante.{" "}
                  <strong>
                    Mas a triagem de câncer de pulmão não é para pessoas com
                    sintomas
                  </strong>
                  . Se você tiver quaisquer sintomas que o preocupem, discuta-os
                  com seu médico.
                </p>
              ),
            },
            {
              title: "O que é a triagem com tomografia computadorizada (TC)?",
              content: (
                <div>
                  <p>
                    A triagem de câncer de pulmão envolve a realização de uma
                    tomografia computadorizada (TC) do seu tórax. O procedimento
                    envolve deitar-se imóvel e passar por um aparelho em formato
                    de rosca enquanto várias imagens são tiradas usando raios X
                    e um computador.
                  </p>
                  <div className="float-right">
                    <MyPager
                      link={"/Portuguese/como-e-realizado-o-rastreamento"}
                      label={"Saiba mais"}
                      arrowDirection={"right"}
                    ></MyPager>
                  </div>
                </div>
              ),
            },
          ],
        },
        {
          title: "2. Custo",
          items: [
            {
              title: "Custo",
              content: (
                <div>
                  <p>
                    Se você não tem seguro, a triagem inicial de câncer de
                    pulmão pode custar entre US$ 100 e US$ 400 do próprio bolso.
                    Visite a página de seguro clicando no botão abaixo para
                    descobrir se o seu plano de saúde cobre a triagem de câncer
                    de pulmão.
                  </p>
                  <p>
                    <div className="float-right">
                      <MyPager
                        link={"/Portuguese/cobertura"}
                        label={"Saiba mais"}
                        arrowDirection={"right"}
                      ></MyPager>
                    </div>
                  </p>
                </div>
              ),
            },
            {
              title: "Onde posso fazer a triagem?",

              content: (
                <div>
                  <p>
                    Recomenda-se que você faça a triagem de câncer de pulmão em
                    uma instalação credenciada pelo Colégio Americano de
                    Radiologia (American College of Radiology). Clique&nbsp;
                    <a
                      target="_blank"
                      href="
                      https://www.acr.org/Clinical-Resources/Lung-Cancer-Screening-Resources/LCS-Locator-Tool"
                      rel="noopener noreferrer"
                    >
                      aqui
                    </a>
                    &nbsp;para ver uma lista de instalações credenciadas.
                  </p>
                </div>
              ),
            },
          ],
        },
        {
          title: "3. Outras coisas a considerar",
          items: [
            {
              title:
                "A triagem de câncer de pulmão não é algo que se faz uma única vez.",
              content: (
                <div>
                  <p>
                    A triagem de câncer de pulmão é um processo. Não é algo que
                    se faz uma única vez. Para obter todo o benefício da
                    triagem, você precisa ser submetido a ela uma vez por ano
                    até não ser mais elegível. Além disso, a triagem pode levar
                    à descoberta de um nódulo que necessite de uma tomografia de
                    acompanhamento em 3-6 meses.
                  </p>
                </div>
              ),
            },

            {
              title: "Sobre possíveis danos",
              content: (
                <div>
                  <p>
                    Você pode querer considerar a possibilidade de resultados
                    falso-positivos e tratamento excessivo decorrentes da
                    triagem de câncer de pulmão.
                  </p>
                  <p>
                    <div className="float-right">
                      <MyPager
                        link={"/Portuguese/beneficios-e-riscos-do-rastreamento"}
                        label={"Saiba mais"}
                        arrowDirection={"right"}
                      ></MyPager>
                    </div>
                  </p>
                </div>
              ),
            },
            {
              title:
                "Outros fatores de risco para câncer de pulmão, além do tabagismo",
              content: (
                <div>
                  <p>
                    Embora o tabagismo seja de longe o maior contribuinte para o
                    risco de câncer de pulmão, exposição ao gás radônio e
                    ocupacional (por exemplo, amianto, arsênico, sílica, cádmio)
                    também são fatores de risco.
                  </p>
                  <p>
                    <div className="float-right">
                      <MyPager
                        link={"/Portuguese/causas-do-cancer-de-pulmao"}
                        label={"Saiba mais"}
                        arrowDirection={"right"}
                      ></MyPager>
                    </div>
                  </p>
                </div>
              ),
            },
          ],
        },
      ],
    },
    causePage: {
      banner_image_source: "/image/smoke.jpg",
      banner_title: "Triagem de Câncer de Pulmão",
      cause_info: {
        title: "Câncer de pulmão e suas causas",
        causes: [
          <p>
            O câncer de pulmão começa com células nas vias aéreas do pulmão que
            sofreram danos permanentes. Esses danos resultam em um crescimento
            anormal e descontrolado de células que pode se espalhar para outras
            partes do seu corpo (metástase). O câncer de pulmão é a principal
            causa de morte relacionada ao câncer no mundo.
          </p>,
          <p>
            Existem muitas causas ligadas ao câncer de pulmão. O fator de risco
            mais importante e bem compreendido é o <strong>tabagismo</strong>.
          </p>,
        ],
      },
      cause_chart: {
        title: "Proporções estimadas das causas de câncer de pulmão*",
        src: "/image/attributable_risks_tiff2_English.png",
        text: "*Alberg AJ, Samet JM. Epidemiology of Lung Cancer. Chest. 2003; 123:21-49.",
      },
      risk_info: {
        title:
          "Outros fatores de risco conhecidos para câncer de pulmão incluem:",
        risks: [
          "exposição prolongada ao radônio (um gás incolor e inodoro ao qual mineiros são expostos, e que pode se acumular no porão de algumas casas);",
          "exposição ocupacional a amianto, arsênio e alguns tipos de cromo e sílica;",
          "um diagnóstico de Doença Pulmonar Obstrutiva Crônica (DPOC), ou enfisema e/ou bronquite crônica, feito por um médico; e",
          "histórico familiar de câncer de pulmão.",
        ],
      },
    },
    comparePage: {
      image_info: {
        link: "/image/fruit.png",
        attribution: {
          link: "https://creativecommons.org/licenses/by-sa/2.0/",
          label: "MicroAssist/Flickr: CC BY-SA 2.0",
        },
      },
      title:
        "Como a tomografia computadorizada para rastreamento de câncer de pulmão se compara a outros tipos de rastreamento?",
      sub_title: "VOCÊ SABIA?",
      content:
        "Em comparação com outros testes comuns de rastreamento de câncer, como a mamografia para câncer de mama, o rastreamento do câncer de pulmão por tomografia computadorizada (TC) de baixa dose é pelo menos tão eficaz em reduzir mortes por câncer de pulmão em indivíduos de alto risco quanto a mamografia é em prevenir mortes por câncer de mama.",
      comparison_table: {
        column_title_list: [
          <div>
            <center>
              Tipo de rastreamento de câncer<sup>1</sup>
            </center>
          </div>,
          <div>
            <center>Anos de exames anuais</center>
          </div>,
          <div>
            <center>
              Número de mortes por câncer evitadas<sup>2</sup>
            </center>
          </div>,
        ],
        row_list: [
          {
            title: "Pulmão",
            cell_list: [3, 3.1],
          },
          {
            title: "Colorretal",
            cell_list: [5, 2.8],
          },
          {
            title: "Mama",
            cell_list: ["", ""],
          },
          {
            title: "Idade 60-69",
            cell_list: [10, 2.6],
          },
          {
            title: "Idade 50-59",
            cell_list: [10, 0.8],
          },
        ],
      },
      foot_note_list: [
        <div>
          <sup>1</sup>Os procedimentos correspondentes ao rastreamento de câncer
          de pulmão, colorretal e de mama aqui são: tomografia computadorizada
          de baixa dose, sigmoidoscopia com pesquisa de sangue oculto nas fezes
          e mamografia, respectivamente.
        </div>,
        <div>
          <sup>2</sup>Isso indica o número de mortes evitadas a cada 1000
          pessoas rastreadas.
        </div>,
      ],
    },
    disclaimerPage: {
      title: "Aviso Legal",
      content:
        "As informações fornecidas neste guia de decisão são apenas para informações gerais de saúde. A calculadora de risco só pode fornecer estimativas do seu risco com base nas respostas que você fornecer. Embora tentemos oferecer as melhores estimativas disponíveis, os números podem não ser precisos para uma pessoa em particular. Pode haver fatores de risco específicos que não são medidos. Portanto, as informações fornecidas neste site não devem ser usadas como substituto para aconselhamento médico de um profissional de saúde. Se você estiver preocupado com câncer de pulmão ou com o seu risco de contrair câncer de pulmão, consulte seu médico ou outro profissional de saúde. Este site contém links para outros sites operados por terceiros, e não garantimos a representação de nenhum Site de Terceiros nem a precisão de seu conteúdo.",
    },
    howPage: {
      title:
        "Coisas que você deve saber sobre o rastreamento de câncer de pulmão",
      banner_image_source: "/image/um-ct-shoot.jpg",
      banner_title: "Rastreamento de Câncer de Pulmão",
      sample_list: [
        {
          title: "P1. O que é o rastreamento de câncer de pulmão por TC?",
          content: (
            <div>
              <p>
                O rastreamento de câncer de pulmão utiliza tomografia
                computadorizada de baixa dose (TCBD), ou seja, uma TC com baixa
                dose de radiação, para encontrar nódulos pulmonares, alguns dos
                quais podem ser câncer. As pessoas que participam do
                rastreamento podem reduzir suas chances de morrer de câncer de
                pulmão.
              </p>
              <p>
                Em geral, o rastreamento de câncer de pulmão não é um teste
                único, mas um processo sob a supervisão do(s) seu(s) médico(s)
                que inclui acompanhamento de quaisquer achados e retorno anual
                para rastreamento enquanto você permanecer elegível.
              </p>
            </div>
          ),
        },
        {
          title: "P2. Quem é elegível para fazer o rastreamento?",
          content: (
            <div>
              <p>
                O rastreamento de câncer de pulmão não é apropriado para todos.
                Você se qualifica para o rastreamento se:
              </p>
              <ul>
                <li>tiver entre 50 e 80 anos,</li>
                <li>tiver fumado nos últimos 15 anos, e</li>
                <li>tiver fumado 20 anos-maço ou mais,</li>
              </ul>
              <p>
                de acordo com o Força-Tarefa de Serviços Preventivos dos EUA (US Preventive Services Task Force). Um
                ano-maço (pack-year) é usado para descrever quantos cigarros
                você fumou ao longo da sua vida, considerando que um maço
                corresponde a 20 cigarros. Se você fumou um maço por dia nos
                últimos 20 anos, ou dois maços por dia nos últimos 10 anos, você
                tem 20 anos-maço. Clique{" "}
                <Link
                  to={"/Portuguese/calculadora-de-anos-maco"}
                  style={{ width: "100%", textDecoration: "none" }}
                  data-on="click"
                  data-event-category="HowPage"
                  data-event-action={"/Portuguese/calculadora-de-anos-maco"}
                >
                  aqui
                </Link>{" "}
                para calcular quantos anos-maço você tem.
              </p>
              <p>
                Observação: Indivíduos de alto risco segurados pelo Medicare têm
                rastreamento a partir de 50 até 77 anos de idade.
              </p>
            </div>
          ),
        },
        {
          title: "P3. O que acontece durante o rastreamento?",
          content: (
            <div>
              <p>
                A descrição a seguir é adaptada do{" "}
                <a
                  href="https://www.radiologyinfo.org/en/info.cfm?pg=screening-lung#part_three"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Colégio Americano de Radiologia (American College of Radiology)
                </a>
                :
              </p>
              <p>
                Para uma TC de baixa dose, você se deitará na mesa de exame.
                Travesseiros e cintas podem ser usados para ajudá-lo a manter a
                posição correta e permanecer imóvel durante o exame. Geralmente,
                você será orientado a manter seus braços acima da cabeça. Em
                seguida, a mesa se moverá pelo scanner até a posição inicial
                correta para as varreduras. Então, enquanto você prende a
                respiração por 5 a 10 segundos, a mesa se movimentará através do
                aparelho enquanto a TC é realizada.
              </p>
            </div>
          ),
        },
        {
          title: "P4. O que acontece após meu primeiro rastreamento?",
          content: (
            <div>
              <p>
                Se um nódulo pulmonar for encontrado em sua tomografia, seu
                médico pode recomendar uma TC de acompanhamento, geralmente de 3
                a 6 meses depois, para verificar se o nódulo não cresceu. Um
                nódulo pulmonar é uma mancha em formato redondo ou oval. No caso
                improvável de o nódulo crescer ou causar preocupação, seu médico
                poderá recomendar testes adicionais, como uma{" "}
                <a
                  href="https://medlineplus.gov/ency/article/007342.htm"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  PET scan
                </a>{" "}
                ou uma biópsia (retirar uma pequena parte do nódulo).
              </p>
              <p>
                No entanto, como a maioria dos nódulos pulmonares encontrados
                não são câncer, você pode precisar apenas retornar em um ano,
                assim como em outros exames anuais de rotina para continuar o
                processo de rastreamento. Lembre-se de que o rastreamento de
                câncer de pulmão não é um teste único: é um processo que deve
                ser feito de maneira adequada sob a supervisão do(s) seu(s)
                médico(s).
              </p>
            </div>
          ),
        },
        {
          title:
            "P5. Quanta radiação receberei do exame de tomografia para rastreamento de câncer de pulmão?",
          content: (
            <div>
              <p>
                Uma TC para rastreamento de câncer de pulmão usa até 90% menos
                radiação do que uma TC convencional de tórax. A dose efetiva de
                radiação aproximada é de 1,5 millisievert (mSv), o que equivale
                a cerca de 6 meses de radiação de fundo “normal” e apresenta
                risco muito baixo. De fato, não foi encontrada correlação entre
                radiação de fundo e risco de câncer. O risco adicional de
                desenvolver câncer de pulmão fatal por um exame em um adulto é
                tão baixo que é difícil de mensurar, com chances estimadas entre
                1 em 100.000 e 1 em 10.000. Para mais informações sobre
                exposição à radiação, você pode visitar{" "}
                <a
                  href="https://www.radiologyinfo.org/en/info.cfm?pg=safety-xray"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  aqui
                </a>{" "}
                ou{" "}
                <a
                  href="http://www.xrayrisk.com/calculator/calculator-normal-studies.php?id=68"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  aqui
                </a>
                .
              </p>
            </div>
          ),
        },
        {
          title: "P6. Onde posso fazer o rastreamento?",
          content: (
            <div>
              <p>
                Você só deve fazer o rastreamento em uma instalação credenciada.
                Você pode encontrar uma próxima de você clicando{" "}
                <a
                  href="https://www.acr.org/Clinical-Resources/Lung-Cancer-Screening-Resources/LCS-Locator-Tool"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  aqui
                </a>
                .
              </p>
            </div>
          ),
        },
      ],
    },
    riskPage: {
      banner_image_source: "/image/gsh-sa-balloons.jpg",
      banner_title: "Reduzindo Seu Risco de Câncer de Pulmão",
      title:
        "A melhor maneira de reduzir suas chances de ter câncer de pulmão é parar de fumar.",

      description: (
        <p>
          Embora o rastreamento por TCBD possa reduzir suas chances de morrer de
          câncer de pulmão, um meio <strong>muito mais</strong> eficaz de
          reduzir suas chances de morrer de câncer de pulmão é parar de fumar,
          caso você ainda fume. Isso é difícil, mas pode ser feito. Para parar
          de fumar, você precisa superar tanto o desejo de nicotina quanto um
          hábito profundamente enraizado. Muitas pessoas citam “estresse” como
          motivo para não tentar parar. Estresse faz parte da vida cotidiana da
          maioria das pessoas, e esperar que o estresse “desapareça” antes de
          fazer algo benéfico para sua saúde é uma receita para o fracasso.
          Precisamos aprender diferentes maneiras de lidar com o estresse, e
          conversar com seu médico sobre suas fontes de estresse é um bom
          começo. Existem muitos recursos que podem ajudá-lo a parar de fumar, e
          alguns fornecerão gratuitamente ou a preços bastante reduzidos
          auxiliares aprovados pela FDA para parar de fumar. Aqui estão apenas
          alguns:
        </p>
      ),
      state_resource_list: [
        { state: "Select a state...", links: [] },
        {
          state: "Alabama",
          links: [
            {
              label:
                "Departamento de Saúde Pública, Prevenção e Controle do Tabaco do Alabama",
              link: "http://www.adph.org/tobacco/Default.asp?id=779",
            },
          ],
        },

        {
          state: "Alaska",
          links: [
            {
              label: "Alaska livre do tabaco",
              link: "http://dhss.alaska.gov/dph/Chronic/Pages/Tobacco/TobaccoFreeAlaska/default.aspx",
            },
          ],
        },

        {
          state: "Arizona",
          links: [
            {
              label: "ASHLine - Arizona's Smokers' Helpline (Linha de Ajuda para Fumantes do Arizona)",
              link: "https://ashline.org/",
            },
            {
              label: "Arizona Livre do Tabaco",
              link: "http://azdhs.gov/tobaccofreeaz/",
            },
          ],
        },

        {
          state: "Arkansas",
          links: [
            {
              label: "Recursos para Cessação do Tabagismo no Arkansas",
              link: "http://www.healthy.arkansas.gov/programs-services/topics/tobacco-cessation-resources",
            },
            {
              label: "Elimine o Tabagismo",
              link: "http://www.stampoutsmoking.com/get-help-to-quit/",
            },
          ],
        },

        {
          state: "California",
          links: [
            {
              label: "Programa de Controle do Tabaco da Califórnia",
              link: "https://www.cdph.ca.gov/Programs/CCDPHP/DCDIC/CTCB/Pages/CessationServicesAndResources.aspx",
            },
            {
              label: "Linha de Ajuda para Fumantes da Califórnia",
              link: "https://www.nobutts.org/",
            },
          ],
        },

        {
          state: "Colorado",
          links: [
            {
              label: "Departamento de Saúde Pública e Meio Ambiente do Colorado",
              link: "https://www.colorado.gov/pacific/cdphe/quit-tobacco",
            },
            {
              label: "Colorado Livre de Tabaco",
              link: "http://www.tobaccofreeco.org/",
            },
          ],
        },

        {
          state: "Connecticut",
          links: [
            {
              label: "Departamento de Saúde Pública de Connecticut",
              link: "http://www.ct.gov/dph/cwp/view.asp?a=3137&q=388064&dphNav=|&dphNav_GID=1841",
            },
          ],
        },

        {
          state: "Delaware",
          links: [
            {
              label: "Programa de Prevenção e Controle do Tabaco de Delaware",
              link: "http://dhss.delaware.gov/dhss/dph/dpc/tobacco.html",
            },
          ],
        },

        {
          state: "Flórida",
          links: [
            {
              label: "Flórida Livre de Tabaco",
              link: "http://www.floridahealth.gov/programs-and-services/prevention/tobacco-free-florida/index.html",
            },
          ],
        },

        {
          state: "Geórgia",
          links: [
            {
              label: "Departamento de Saúde Pública da Geórgia - Pronto para Parar",
              link: "https://dph.georgia.gov/ready-quit",
            },
          ],
        },

        {
          state: "Havaí",
          links: [
            {
              label: "Departamento de Saúde do Havaí - Controle do Tabaco",
              link: "http://health.hawaii.gov/tobacco/home/cessation/",
            },
            {
              label: "Linha de Cessação do Tabagismo do Havaí",
              link: "http://hawaiiquitline.org",
            },
          ],
        },

        {
          state: "Idaho",
          links: [
            {
              label: "Programa de Prevenção e Controle do Tabaco de Idaho",
              link: "http://www.healthandwelfare.idaho.gov/Health/TobaccoPreventionandControl/tabid/324/Default.aspx",
            },
            { label: "Projeto Filter", link: "http://projectfilter.org/" },
          ],
        },

        {
          state: "Illinois",
          links: [
            {
              label: "Linha de Cessação do Tabagismo de Illinois",
              link: "http://quityes.org/home.html",
            },
          ],
        },

        {
          state: "Indiana",
          links: [
            {
              label: "Departamento de Saúde do Estado de Indiana",
              link: "http://www.in.gov/isdh/tpc/2353.htm",
            },
            {
              label: "Pare Agora Indiana",
              link: "http://www.quitnowindiana.com/",
            },
          ],
        },

        {
          state: "Iowa",
          links: [
            {
              label: "Linha de Cessação do Tabagismo de Iowa",
              link: "https://iowa.quitlogix.org/",
            },
          ],
        },

        {
          state: "Kansas",
          links: [
            {
              label: "Departamento de Saúde e Meio Ambiente do Kansas",
              link: "http://www.kdheks.gov/tobacco/cessation.html",
            },
            {
              label: "KanQuit!",
              link: "http://www.ksquit.org/",
            },
          ],
        },
        {
          state: "Kentucky",
          links: [
            {
              label: "Programa de Prevenção e Cessação de Tabaco de Kentucky",
              link: "http://chfs.ky.gov/dph/mch/hp/tobacco.htm",
            },
          ],
        },
        {
          state: "Louisiana",
          links: [
            {
              label: "Pare de fumar conosco, Louisiana",
              link: "http://www.quitwithusla.org/",
            },
          ],
        },
        {
          state: "Maine",
          links: [
            {
              label: "Parceria por um Maine Livre de Tabaco",
              link: "http://www.tobaccofreemaine.org/",
            },
          ],
        },
        {
          state: "Maryland",
          links: [
            {
              label: "Fumar para aqui – Recurso de cessação de Maryland",
              link: "https://smokingstopshere.com/",
            },
            {
              label: "MDQuit",
              link: "http://mdquit.org/",
            },
          ],
        },
        {
          state: "Massachusetts",
          links: [
            {
              label: "Faça do tabagismo história",
              link: "http://makesmokinghistory.org/",
            },
          ],
        },
        {
          state: "Michigan",
          links: [
            {
              label: "Departamento de Saúde Comunitária de Michigan",
              link: "http://www.michigan.gov/mdhhs/0,5885,7-339-71550_2955_2973_53244---,00.html",
            },
            {
              label:
                "Programas de Tratamento do Tabaco da Universidade de Michigan",
              link: "https://hr.umich.edu/benefits-wellness/health-well-being/mhealthy/faculty-staff-well-being/alcohol-tobacco-programs/tobacco-consultation-service",
            },
          ],
        },
        {
          state: "Minnesota",
          links: [
            {
              label: "Departamento de Saúde de Minnesota",
              link: "http://www.health.state.mn.us/divs/hpcd/tpc/quit.html",
            },
            {
              label: "Serviços QUITPLAN. Sem julgamentos. Apenas ajuda.",
              link: "https://www.quitplan.com/",
            },
          ],
        },
        {
          state: "Mississippi",
          links: [
            {
              label: "Escritório de Controle de Tabaco do Mississippi",
              link: "http://www.msdh.state.ms.us/msdhsite/_static/43,0,94.html#community",
            },
            {
              label: "Linha de ajuda para parar de fumar do Mississippi",
              link: "http://www.quitlinems.com/",
            },
          ],
        },
        {
          state: "Missouri",
          links: [
            {
              label: "Departamento de Saúde e Serviços Sênior do Missouri",
              link: "http://health.mo.gov/living/wellness/tobacco/smokingandtobacco/tobaccocontrol.php",
            },
          ],
        },
        {
          state: "Montana",
          links: [
            {
              label: "Programa de Prevenção ao Uso de Tabaco de Montana",
              link: "http://dphhs.mt.gov/publichealth/mtupp",
            },
            {
              label: "Linha de Ajuda de Tabaco de Montana",
              link: "https://montana.quitlogix.org/",
            },
          ],
        },
        {
          state: "Nebraska",
          links: [
            {
              label: "Nebraska Livre de Fumo",
              link: "http://dhhs.ne.gov/publichealth/Pages/smokefree_sf_ql.aspx",
            },
          ],
        },
        {
          state: "Nevada",
          links: [
            {
              label: "Programa de Prevenção e Controle do Tabaco de Nevada",
              link: "http://dpbh.nv.gov/Programs/TPC/Tobacco_Prevention_and_Control_-_Home/",
            },
            {
              label: "Coalizão de Prevenção ao Tabaco de Nevada",
              link: "http://www.tobaccofreenv.org/tobacco-information/quitting-smoking",
            },
          ],
        },
        {
          state: "New Hampshire",
          links: [
            {
              label:
                "Programa de Prevenção e Controle do Tabaco de New Hampshire",
              link: "http://www.dhhs.state.nh.us/dphs/tobacco/index.htm",
            },
            {
              label: "Linha de Ajuda de Tabaco de New Hampshire",
              link: "http://trytostopnh.org/",
            },
          ],
        },
        {
          state: "New Jersey",
          links: [
            {
              label: "Programa de Controle do Tabaco de New Jersey",
              link: "http://www.nj.gov/health/fhs/tobacco",
            },
            {
              label: "Linha de Ajuda de New Jersey",
              link: "http://www.njquitline.org/treatment.html",
            },
          ],
        },
        {
          state: "New Mexico",
          links: [
            {
              label: "TUPAC: Prevenção e Controle do Uso de Tabaco",
              link: "http://nmtupac.com/#home",
            },
          ],
        },
        {
          state: "New York",
          links: [
            {
              label: "Linha de Ajuda para Fumantes do Estado de Nova York",
              link: "https://nysmokefree.com/",
            },
          ],
        },
        {
          state: "North Carolina",
          links: [
            {
              label: "QuitlineNC",
              link: "http://www.quitlinenc.com/",
            },
          ],
        },
        {
          state: "North Dakota",
          links: [
            {
              label:
                "Programa de Prevenção e Controle do Tabaco de Dakota do Norte",
              link: "http://www.ndhealth.gov/tobacco/",
            },
            {
              label: "Programas de Cessação/Parar de Fumar de Dakota do Norte",
              link: "http://www.ndhealth.gov/tobacco/cessation.htm",
            },
          ],
        },
        {
          state: "Ohio",
          links: [
            {
              label: "Programa de Tabaco do Departamento de Saúde de Ohio",
              link: "http://www.odh.ohio.gov/en/odhprograms/eh/quitnow/Tobacco/Cessation/Cessation",
            },
          ],
        },
        {
          state: "Oklahoma",
          links: [
            {
              label:
                "Programas de Prevenção e Redução do Uso de Tabaco em Oklahoma",
              link: "http://www.ok.gov/health/Wellness/Tobacco_Prevention/Programs/index.html",
            },
          ],
        },
        {
          state: "Oregon",
          links: [
            {
              label: "Programa de Prevenção e Educação sobre Tabaco de Oregon",
              link: "http://www.oregon.gov/oha/PH/PreventionWellness/TobaccoPrevention/GetHelpQuitting/Pages/oregonquitline.aspx",
            },
            {
              label: "Smokefree Oregon",
              link: "https://www.quitnow.net/oregon/",
            },
          ],
        },
        {
          state: "Pennsylvania",
          links: [
            {
              label: "Prevenção e Controle do Tabaco na Pensilvânia",
              link: "http://www.health.pa.gov/My%20Health/Healthy%20Living/Smoke%20Free%20Tobacco/Pages/default.aspx#.WbqmgfmGPcs",
            },
            {
              label: "Linha Gratuita para Parar de Fumar da Pensilvânia",
              link: "http://www.health.pa.gov/My%20Health/Healthy%20Living/Smoke%20Free%20Tobacco/Pages/PA-Free-Quitline.aspx#.WbrmPdOGORt",
            },
          ],
        },
        {
          state: "Rhode Island",
          links: [
            {
              label: "Programa de Controle do Tabaco de Rhode Island",
              link: "http://www.health.ri.gov/programs/tobaccocontrol/",
            },
            {
              label: "Quit Now RI",
              link: "http://www.quitnowri.com/",
            },
          ],
        },
        {
          state: "South Carolina",
          links: [
            {
              label: "Cessação do Tabaco na Carolina do Sul",
              link: "http://www.scdhec.gov/Health/TobaccoCessation/",
            },
          ],
        },
        {
          state: "South Dakota",
          links: [
            {
              label:
                "Programa de Prevenção e Controle do Tabaco de Dakota do Sul",
              link: "http://doh.sd.gov/prevention/tobacco/",
            },
            {
              label: "Linha de Ajuda para Parar de Fumar de Dakota do Sul",
              link: "https://www.sdquitline.com/",
            },
          ],
        },
        {
          state: "Tennessee",
          links: [
            {
              label: "Linha de Ajuda para Parar de Fumar do Tennessee",
              link: "http://health.state.tn.us/tobaccoquitline.htm",
            },
          ],
        },
        {
          state: "Texas",
          links: [
            {
              label: "Departamento de Serviços de Saúde do Estado do Texas",
              link: "https://www.dshs.state.tx.us/tobacco/links.shtm",
            },
            {
              label: "O tabaco é nojento",
              link: "http://www.ducktexas.org/",
            },
          ],
        },
        {
          state: "Utah",
          links: [
            {
              label: "Programa de Prevenção e Controle do Tabaco de Utah",
              link: "http://www.tobaccofreeutah.org/",
            },
            {
              label: "Way to Quit",
              link: "http://waytoquit.org/",
            },
          ],
        },

        {
          state: "Vermont",
          links: [
            {
              label: "Departamento de Saúde de Vermont",
              link: "http://www.healthvermont.gov/prevent/tobacco/quit_smoking.aspx",
            },
            {
              label: "802 Quits: O Recurso de Parar de Fumar em Vermont",
              link: "http://802quits.org/",
            },
          ],
        },
        {
          state: "Virginia",
          links: [
            {
              label: "Projeto de Controle do Uso de Tabaco da Virgínia",
              link: "http://www.vdh.virginia.gov/ofhs/prevention/tucp/",
            },
          ],
        },

        {
          state: "Washington",
          links: [
            {
              label:
                "Recursos de Tabaco do Departamento de Saúde do Estado de Washington",
              link: "http://www.doh.wa.gov/ForPublicHealthandHealthcareProviders/PublicHealthSystemResourcesandServices/LocalHealthResourcesandTools/Tobacco",
            },
          ],
        },

        {
          state: "West Virginia",
          links: [
            {
              label: "Recursos de Prevenção ao Tabaco na Virgínia Ocidental",
              link: "http://www.dhhr.wv.gov/wvdtp/cessation/Pages/default.aspx",
            },
          ],
        },

        {
          state: "Wisconsin",
          links: [
            {
              label: "Programa de Prevenção e Controle do Tabaco de Wisconsin",
              link: "https://www.dhs.wisconsin.gov/tobacco/index.htm",
            },
            {
              label: "Linha de Ajuda para Parar de Fumar de Wisconsin",
              link: "http://www.ctri.wisc.edu/quitline.html",
            },
          ],
        },

        {
          state: "Wyoming",
          links: [
            {
              label:
                "Programa de Prevenção ao Tabaco do Departamento de Saúde de Wyoming",
              link: "https://health.wyo.gov/publichealth/prevention/tobacco-prevention/wqtp/",
            },
          ],
        },
      ],
      resources_quit: [
        {
          link: "",
          label: (
            <a href="tel:8007848669">
              Ligue para 1-800-QUIT-NOW (800-784-8669)
            </a>
          ),
        },
        {
          link: "http://smokefree.gov/",
          label: "Smokefree.gov",
        },
        {
          link: "http://betobaccofree.hhs.gov/quit-now/index.html",
          label: "BeTobaccoFree.gov",
        },
        {
          link: "http://www.cancer.org/healthy/stayawayfromtobacco/guidetoquittingsmoking/index",
          label: "Sociedade Americana do Câncer",
        },
      ],
      resources_instructions:
        "Escolha um estado no menu suspenso para recursos especificamente disponíveis lá.",
      radon_instructions:
        "O radônio é outro fator de risco evitável para câncer de pulmão. Saiba como você pode reduzir seu risco em:",
      resources_radon: [
        {
          link: "https://www.epa.gov/sites/production/files/2016-12/documents/2016_a_citizens_guide_to_radon.pdf",
          label:
            "Guia do Cidadão sobre Radônio compilado pela Agência de Proteção Ambiental",
        },
        {
          link: "http://www.cdc.gov/nceh/radiation/brochure/profile_radon.htm",
          label: "Centros de Controle e Prevenção de Doenças",
        },
      ],
    },
    packPage: {
      title: "Calculadora de anos-maço",
      instruction: {
        normal:
          "Um ano-maço é usado para descrever quantos cigarros você fumou na sua vida, considerando que um maço equivale a 20 cigarros. Se você fumou um maço por dia nos últimos 20 anos, ou dois maços por dia nos últimos 10 anos, você tem 20 anos-maço. Em outras palavras, anos-maço é uma forma de medir a exposição ao fumo, levando em conta há quanto tempo você fuma e quanto fuma. Atualmente, ter 20 anos-maço ou mais é um dos critérios que precisam ser cumpridos para que se recomende o rastreamento.",
      },

      sub_title:
        "POR FAVOR, RESPONDA A ESTAS DUAS PERGUNTAS PARA CALCULAR SEUS ANOS-MAÇO.",
      submit_button_label: "Calcular",
      calculation_result_template:
        "Sua exposição ao tabaco é de x_number anos-maço.", // x_number será substituído pelo resultado do cálculo, não altere.
      pack_questions: {
        formYears: {
          label: "1. Por quantos anos você fumou?",
          placeholder: "quantos anos?",
        },
        formAmount: {
          label:
            "2. Em média, quantos cigarros você fumou por dia? Existem 20 cigarros em um maço.",
          placeholder: "cigarros por dia?",
        },
      },
    },
    calculatePage: {
      banner_image_source: "/image/areachart2.png",
      banner_title: "Seu Risco de Câncer de Pulmão",
      title:
        "Se você fuma, a melhor forma de reduzir seu risco de câncer de pulmão é parar de fumar.",

      instruction: {
        normal: (
          <div>
            <p>
              Preencha as informações abaixo para descobrir se você está no
              grupo para o qual o rastreamento é recomendado pelo&nbsp;
              <a
                target="_blank"
                href="https://www.uspreventiveservicestaskforce.org/Page/Document/RecommendationStatementFinal/lung-cancer-screening"
                rel="noopener noreferrer"
              >
                Força-Tarefa de Serviços Preventivos dos EUA (US Preventive Services Task Force)
              </a>
              .&nbsp; A calculadora também indicará o quanto você pode se
              beneficiar ao fazer o rastreamento. Isso o ajudará a determinar
              melhor se o seu benefício potencial ao realizar o rastreamento
              supera os riscos.
            </p>
          </div>
        ),
        strong_text_list: ["* INDICA CAMPOS OBRIGATÓRIOS"],
      },
      submit_button_label: "Enviar",
      default_select_label: " -- selecione -- ",
      error_input_guidance:
        "Olá, um ou mais dos campos preenchidos podem conter um erro de digitação. Por favor, role para baixo e verifique.",
      tall_question: (
        <div>
          9. Qual é a sua altura? &nbsp;
          <a
            target="_blank"
            href="https://share.streamlit.io/should-i-screen/unit-converter/main?lan=en"
            rel="noopener noreferrer"
          >
            Me ajude a converter centímetros para pés e polegadas.
          </a>
        </div>
      ),
      risk_questions: {
        formOld: {
          label: "1. Qual a sua idade?* ",
          placeholder: "",
          help_text:
            "A Pergunta 1 é obrigatória, e a faixa de idade válida para esta calculadora é de 18 a 100 anos.",
        },
        formCurrentStatus: {
          label: "2. Qual o seu status atual em relação ao cigarro?*",
          placeholder: "",
          options: [
            { label: "Fumante", value: 1 },
            { label: "Ex-fumante", value: 0 },
            { label: "Nunca fumei", value: 2 },
          ],
          help_text: "A Pergunta 2 é obrigatória.",
        },
        formAgeLastSmoke: {
          label: "2.1. Com que idade você parou de fumar pela última vez?*",
          placeholder: "",
          options: [],
          help_text:
            "Você indicou que é ex-fumante. Certifique-se de que inseriu um número menor que sua idade atual na Pergunta 1.",
        },
        formYearsSmoking: {
          label: "3. Por quantos anos, ao todo, você fumou cigarros?*",
          placeholder: "",
          options: [],
          help_text:
            "A Pergunta 3 é obrigatória. Verifique se este valor é menor que sua idade atual e se é um número válido.",
        },
        formCigarettesPerDay: {
          label:
            "4. Em média, quantos cigarros você fuma/fumava por dia?* Existem 20 cigarros em um maço.",
          placeholder: "",
          options: [],
          help_text:
            "A Pergunta 4 é obrigatória. Verifique se você inseriu um número entre 0 e 200.",
        },
        formGender: {
          label: "5. Qual o seu gênero?",
          placeholder: "",
          options: [
            { label: "Masculino", value: "male" },
            { label: "Feminino", value: "female" },
          ],
        },
        formInsurance: {
          label: <div>6. Você tem cobertura pelo Medicare? 🏥</div>,
          placeholder: "",
          options: [
            { label: "Sim", value: "yes" },
            { label: "Não", value: "no" },
            { label: "Não sei/Não se aplica", value: "unknown" },
          ],
        },
        formHighestGradeCompleted: {
          label:
            "7. Qual foi o nível mais alto de escolaridade que você concluiu? ",
          placeholder: "",
          options: [
            { label: "Menos que o ensino médio", value: 0 },
            { label: "Ensino médio completo", value: 1 },
            { label: "Algum treinamento após o ensino médio", value: 2 },
            { label: "Alguma faculdade", value: 3 },
            { label: "Graduação completa", value: 4 },
            { label: "Pós-graduação ou formação profissional", value: 5 },
          ],
        },
        formRaceEthnicity: {
          label: "8. Como você descreveria sua raça/etnia?",
          placeholder: "",
          options: [
            { label: "Branca", value: 0 },
            { label: "Negra", value: 1 },
            { label: "Hispânica", value: 2 },
            { label: "Asiática", value: 3 },
            { label: "Nativo Americano ou Nativo do Alasca", value: 4 },
            {
              label: "Nativo do Havaí ou Ilhas do Pacífico",
              value: 5,
            },
          ],
        },
        formTallFeet: {
          label: "",
          placeholder: "",
          right_side_label: "pés",
          options: [
            { label: "3", value: 3 },
            { label: "4", value: 4 },
            { label: "5", value: 5 },
            { label: "6", value: 6 },
            { label: "7", value: 7 },
            { label: "8", value: 8 },
            { label: "9", value: 9 },
          ],
        },
        formTallInch: {
          label: "",
          placeholder: "",
          right_side_label: "pol.",
          options: [
            { label: "0", value: 0 },
            { label: "1", value: 1 },
            { label: "2", value: 2 },
            { label: "3", value: 3 },
            { label: "4", value: 4 },
            { label: "5", value: 5 },
            { label: "6", value: 6 },
            { label: "7", value: 7 },
            { label: "8", value: 8 },
            { label: "9", value: 9 },
            { label: "10", value: 10 },
            { label: "11", value: 11 },
          ],
        },
        formWeight: {
          label: (
            <div>
              10. Quanto você pesa? (libras) &nbsp;
              <a
                target="_blank"
                href="https://share.streamlit.io/should-i-screen/unit-converter/main?lan=en"
                rel="noopener noreferrer"
              >
                Me ajude a converter quilogramas para libras.
              </a>
            </div>
          ),
          placeholder: "",
          options: [],
          help_text:
            "Verifique se você inseriu um número entre 45 e 1000 na Pergunta 9. Não é um campo obrigatório. Deixar em branco atribuirá um valor padrão ao nosso cálculo.",
        },
        formDoctorToldCancer: {
          label: "11. Algum médico já lhe disse que você tem câncer?",
          placeholder: "",
          options: [
            { label: "Sim", value: 1 },
            { label: "Não", value: 0 },
          ],
        },
        formFamilyHistory: {
          label: "12. Alguém na sua família tem histórico de câncer de pulmão?",
          placeholder: "",
          options: [
            { label: "Sim", value: 1 },
            { label: "Não", value: 0 },
          ],
        },
        formDoctorToldCOPD: {
          label:
            "13. Algum médico já lhe disse que você tem doença pulmonar crônica, também conhecida como DPOC (bronquite crônica ou enfisema)? ",
          options: [
            { label: "Sim", value: 1 },
            { label: "Não", value: 0 },
          ],
        },
      },
    },
    feedbackPage: {
      title: "Agradecemos seu feedback!",
      submitted_message: "Obrigado!",
      default_select_label: " -- selecione -- ",
      submit_button_label: "Enviar",
      feedback_questions: {
        formGender: {
          label: "Eu sou",
          placeholder: "",
          options: [
            { label: "Feminino", value: 0 },
            { label: "Masculino", value: 1 },
          ],
        },
        formSmokeStatus: {
          label: "Eu...",
          placeholder: "",
          options: [
            { label: "sou fumante.", value: 1 },
            { label: "era fumante.", value: 0 },
            { label: "nunca fumei.", value: 2 },
          ],
        },
        formAge: {
          label: "Qual a sua idade?",
          placeholder: "50",
          options: [],
        },
        formCalculatorResult: {
          label:
            "De acordo com o que você inseriu na calculadora, você estava:",
          placeholder: "",
          options: [
            {
              label: "Elegível para ser rastreado",
              value: "Elegível para ser rastreado",
            },
            {
              label: "Não elegível para ser rastreado",
              value: "Não elegível para ser rastreado",
            },
            {
              label: "Risco muito baixo para ser calculado com precisão",
              value: "Risco muito baixo para ser calculado com precisão",
            },
          ],
        },
        formInformationReceiver: {
          label: "Estou procurando informações para",
          placeholder: "",
          options: [
            { label: "Mim mesmo(a)", value: "Myself" },
            { label: "Meu( minha ) parceiro(a)", value: "My partner" },
            { label: "Meu(s) familiar(es)", value: "My family member(s)" },
            { label: "Meus(as) amigo(as)", value: "My friend(s)" },
            { label: "Meus pacientes", value: "My patient(s)" },
          ],
        },
        formFindAllInformation: {
          label:
            "Encontrei todas as informações que procurava sobre rastreamento de câncer de pulmão.",
          placeholder: "",
          options: [
            { label: "Discordo Fortemente", value: -2 },
            { label: "Discordo", value: -1 },
            { label: "Neutro", value: 0 },
            { label: "Concordo", value: 1 },
            { label: "Concordo Fortemente", value: 2 },
          ],
        },
        formHelpConversationDoctor: {
          label:
            "As informações que encontrei me ajudaram a conversar com meu médico sobre rastreamento de câncer de pulmão.",
          placeholder: "",
          options: [
            { label: "Discordo Fortemente", value: -2 },
            { label: "Discordo", value: -1 },
            { label: "Neutro", value: 0 },
            { label: "Concordo", value: 1 },
            { label: "Concordo Fortemente", value: 2 },
          ],
        },
        formHelpDecision: {
          label:
            "O site me ajudou na minha decisão sobre rastreamento de câncer de pulmão.",
          placeholder: "",
          options: [
            { label: "Discordo Fortemente", value: -2 },
            { label: "Discordo", value: -1 },
            { label: "Neutro", value: 0 },
            { label: "Concordo", value: 1 },
            { label: "Concordo Fortemente", value: 2 },
          ],
        },
        formInterestReason: {
          label:
            "Estou interessado(a) em rastreamento de câncer de pulmão porque...",
          placeholder: "Ex.: Tenho histórico familiar de câncer.",
        },
        formComment: {
          label:
            "Fique à vontade para enviar quaisquer outros comentários que ache que nos ajudarão a melhorar o site:",
          placeholder: "",
        },
      },
    },
    resultPage: {
      print_button_label: "Imprimir",
      title: "Rastreamento de Câncer de Pulmão:",
      sub_title: "Devo fazer?",
      risk_spectrum: {
        risk_statement: "Seu risco de câncer de pulmão:",
        low_risk_label: <span>&#60; 1%: baixo risco</span>,
        intermediate_risk_label: <span>1-2%: risco intermediário</span>,
        high_risk_label: <span>&#62; 2%: alto risco</span>,
      },
      risk_confirmation: {
        label:
          "De acordo com o gráfico, meu risco de desenvolver câncer de pulmão é:",
        placeholder: "",
        options: [
          { label: "Alto", value: "High" },
          { label: "Intermediário", value: "Intermediate" },
          { label: "Baixo", value: "Low" },
          { label: "Não sei", value: "Don't know" },
        ],
      },
      comparison_chart_info_list: [
        {
          chartTitle: "Não rastreado",
          legendTitle: {
            1000: "Em 1000 pessoas como você",
            100: "Em 100 pessoas como você",
          },
          legendMap: {
            die: "morrem de câncer de pulmão",
            "not die": "não morreram de câncer de pulmão",
          },
        },
        {
          chartTitle: "Rastreado",
          legendTitle: {
            1000: "Em 1000 pessoas como você",
            100: "Em 100 pessoas como você",
          },
          legendMap: {
            die: "morrem de câncer de pulmão",
            "fewer die": {
              full: "menos pessoas morreram de câncer de pulmão devido ao rastreamento",
              list: [
                "menos pessoas morreram de câncer de pulmão devido ",
                "ao rastreamento",
              ],
            },
            "not die": "não morreram de câncer de pulmão",
          },
        },
      ],
      benefit_confirmation: {
        label:
          "Verificação rápida: com base nas informações acima, considero que os benefícios do rastreamento, em relação ao meu risco de desenvolver câncer de pulmão nos próximos 6 anos, são ",
        placeholder: "",
        options: [
          { label: "Suficientemente altos", value: "High enough" },
          { label: "Muito baixos", value: "Too low" },
          { label: "Não sei", value: "Don't know" },
        ],
      },
      risk_section: {
        title: "Riscos",
        statement_list: [
          "120 em 1000 pessoas que foram rastreadas encontraram um nódulo pulmonar que não era câncer.",
          "13 em 1000 passaram por um procedimento invasivo, como biópsia ou cirurgia, devido a um nódulo pulmonar que não era câncer.",
          "Menos de 1 em 1000 tiveram uma complicação grave por procedimentos invasivos.",
          "Menos de 10% dos casos de câncer de pulmão detectados pelo rastreamento são considerados sobrediagnosticados*.",
        ],
        foot_note:
          "* Esses casos de câncer sobrediagnosticados podem não ter causado danos se não tivessem sido encontrados, e o paciente pode não ter sentido nenhum sintoma. No entanto, ao encontrar esse tipo de câncer, o paciente pode receber um tratamento desnecessário, com potencial para complicações. Observe que, depois que um câncer é encontrado, não é possível saber se teria sido um sobrediagnóstico ou não.",
      },
      benefit_section: {
        title: "Benefícios",
        statement_end:
          "menos pessoas como você morrerão de câncer de pulmão entre aquelas que foram rastreadas, em comparação com aquelas que não foram rastreadas.",
      },
      instructions: {
        beforeSubmission: [
          "Até agora, você deve ter uma noção do que é o rastreamento e do que ele pode ou não fazer. Será útil para seu médico entender como você se sente em relação aos prós e contras do rastreamento. Por favor, marque qual frase abaixo melhor descreve sua resposta à seguinte pergunta. Não há resposta certa ou errada!",
          "NA SUA OPINIÃO, SEU BENEFÍCIO PESSOAL EM FAZER O RASTREAMENTO JUSTIFICA SER RASTREADO(A)? ESCOLHA UMA OPÇÃO ABAIXO.",
        ],
        afterSubmission: [
          "Lembre-se de conversar com seu médico sobre seu ponto de vista a respeito do rastreamento de câncer de pulmão!",
        ],
      },
      default_select_label: " -- selecione -- ",
      submit_button_label: "Enviar",
      click_for_pdf_msg:
        "Clique na imagem para obter uma versão para impressão dos resultados.",
      getEligibilityStatement: function (
        sex,
        risk,
        eligibility,
        insurance,
        old,
        packYears,
        neverSmoked = false
      ) {
        // sex: male, female
        // risk: 0.0 ~
        // eligibility: yes, no
        // insurance: yes, no, unknown
        // Old = -1;
        // yearsSmoking = -1;
        // cigarettesPerDay = -1;
        var result = "Elegibilidade Padrão";

        if (neverSmoked) {
          result = (
            <div>
              A tomografia computadorizada para rastreamento de câncer de pulmão
              não é recomendada para pessoas que nunca fumaram.
            </div>
          );
          return result;
        }

        switch (eligibility) {
          case "yes":
            result = "";

            if (insurance == "yes") {
              // M
              result = (
                <div>
                  Dada a sua idade e histórico de tabagismo, você está{" "}
                  <strong>elegível</strong> para o rastreamento de acordo com os
                  critérios do US Preventive Services Task Force.
                </div>
              );
            } else {
              if (old > 77) {
                result = (
                  <div>
                    Dada a sua idade e histórico de tabagismo, você está{" "}
                    <strong>elegível</strong> para o rastreamento de acordo com
                    os critérios da Força-Tarefa de Serviços Preventivos dos EUA (US Preventive Services Task Force). No
                    entanto, o Medicare cobre o rastreamento de câncer de pulmão
                    apenas até 77 anos. Por favor, veja nossa &nbsp;
                    <Link
                      to={"/Portuguese/cobertura"}
                      style={{ width: "100%" }}
                      data-on="click"
                      data-event-category="CoveragePage"
                      data-event-action={"/Portuguese/cobertura"}
                    >
                      página de cobertura de seguro&nbsp;
                    </Link>{" "}
                    ou entre em contato com seu provedor de seguro para
                    verificar se há outras opções que possam cobrir os custos do
                    rastreamento de câncer de pulmão.
                  </div>
                );
              } else {
                result = (
                  <div>
                    Dada a sua idade e histórico de tabagismo, você está{" "}
                    <strong>elegível</strong> para o rastreamento de acordo com
                    os critérios da Força-Tarefa de Serviços Preventivos dos EUA (US Preventive Services Task Force).
                  </div>
                );
              }
            }
            break;
          case "no":
            result = (
              <p>
                Dada a sua idade e histórico de tabagismo, você{" "}
                <strong>não é elegível*</strong> para o rastreamento de acordo
                com os critérios da Força-Tarefa de Serviços Preventivos dos EUA (US Preventive Services Task Force).
              </p>
            );
            break;
          default:
            result = "";
            break;
        }

        return result;
      },
      getChanceStatement(sex, risk, eligibility, insurance, old, packYears) {
        // sex: male, female
        // risk: 0.0 ~
        // eligibility: yes, no
        // medicare: yes, no, unknown
        var result = "Chance Padrão";

        console.log(
          `getChanceStatement(), old: ${old}, packYears: ${packYears}`
        );

        let tailorMessage = "";

        let oldInRange = old >= 50 && old <= 54;
        let packYearsInRange = packYears >= 20 && packYears <= 29;

        if (oldInRange && packYearsInRange) {
          tailorMessage = `Como você tem entre 50 e 54 anos e um histórico de tabagismo de 20-29 anos-maço, você agora é elegível para o rastreamento de câncer de pulmão de acordo com as recomendações mais recentes da Força-Tarefa de Serviços Preventivos dos EUA (US Preventive Services Task Force). Pode ser que seu seguro ainda não cubra, então fale com seu provedor de seguro para mais informações sobre quando haverá cobertura.`;
        } else if (!oldInRange && packYearsInRange) {
          tailorMessage = `Como você tem um histórico de tabagismo de 20-29 anos-maço, você agora é elegível para o rastreamento de câncer de pulmão de acordo com as recomendações mais recentes da Força-Tarefa de Serviços Preventivos dos EUA (US Preventive Services Task Force). Pode ser que seu seguro ainda não cubra, então fale com seu provedor de seguro para mais informações sobre quando haverá cobertura.`;
        } else if (oldInRange && !packYearsInRange) {
          tailorMessage = `Como você tem entre 50 e 54 anos, você agora é elegível para o rastreamento de câncer de pulmão de acordo com as recomendações mais recentes da Força-Tarefa de Serviços Preventivos dos EUA (US Preventive Services Task Force). Pode ser que seu seguro ainda não cubra, então fale com seu provedor de seguro para mais informações sobre quando haverá cobertura.`;
        }

        if (risk == 0) {
          // smoker or not
          result = (
            <div>
              A chance de você desenvolver câncer de pulmão nos próximos 6 anos
              é muito baixa para ser medida com precisão. Converse com seu
              médico sobre o rastreamento se estiver preocupado com seu risco de
              câncer de pulmão, pois ele entenderá melhor a sua situação.
            </div>
          );
        } else if (eligibility == "no") {
          if (risk >= 1.5) {
            result = (
              <Fragment>
                <p>
                  *No entanto, a chance de você desenvolver câncer de pulmão nos
                  próximos 6 anos é de {risk}%, que é onde acreditamos que os
                  benefícios do rastreamento são grandes o suficiente para
                  considerar a tomografia computadorizada como opção. Você deve
                  pensar em conversar com seu médico sobre se o rastreamento de
                  câncer de pulmão ainda pode ser uma boa escolha para você.
                </p>
              </Fragment>
            );
          } else {
            result = (
              <div>
                A chance de você desenvolver câncer de pulmão nos próximos 6
                anos é de {risk}%. Converse com seu médico sobre a opção de
                fazer ou não o rastreamento, pois ele entenderá melhor a sua
                situação.
              </div>
            );
          }
        } else if (eligibility == "yes") {
          if (risk < 1.5) {
            result = (
              <Fragment>
                <p>{tailorMessage}</p>
                <p>
                  *No entanto, a chance de você desenvolver câncer de pulmão nos
                  próximos 6 anos é de {risk}%, o que significa que acreditamos
                  que os benefícios do rastreamento não são grandes o suficiente
                  para considerar a tomografia computadorizada como opção. Você
                  deve pensar em conversar com seu médico sobre se o
                  rastreamento de câncer de pulmão ainda pode ser uma boa
                  escolha para você.
                </p>
              </Fragment>
            );
          } else {
            result = (
              <div>
                <p>
                  A chance de você desenvolver câncer de pulmão nos próximos 6
                  anos é de {risk}%. Converse com seu médico sobre a opção de
                  fazer ou não o rastreamento, pois ele entenderá melhor a sua
                  situação.
                </p>
                <p>{tailorMessage}</p>
              </div>
            );
          }
        }
        return result;
      },
      getExplanationStatement(sex, risk, eligibility, insurance) {
        // sex: male, female
        // risk: 0.0 ~
        // eligibility: yes, no
        // insurance: yes, no, unknown
        var result = "Explicação Padrão";

        // Math.round()

        // deal with it.

        // method 2: encapsulate into a function in  MyCalcuataion
        result = (
          <div>
            Em comparação com outras pessoas como você, haverá{" "}
            {MyCalculation.calculateSavedByScreeningFromRisk(risk) + " "}
            menos mortes em cada 1000 nos próximos 6 anos se você fizer o
            rastreamento.
          </div>
        );

        return result;
      },
      getBenefitTitle(sex, risk, eligibility, insurance) {
        // sex: male, female
        // risk: 0.0 ~
        // eligibility: yes, no
        // insurance: yes, no, unknown

        // we only need risk

        var result = "Benefício Padrão";

        result =
          MyCalculation.calculateSavedByScreeningFromRisk(risk) + " em 1000";

        return result;
      },
      reflection_questions: {
        formPersonalBenefit: {
          label: "",
          placeholder: "",
          options: [
            {
              label:
                "Sim. Meu benefício com o rastreamento é suficiente para justificar fazê-lo.",
              value:
                "Sim. Meu benefício com o rastreamento é suficiente para justificar fazê-lo.",
            },
            {
              label:
                "Talvez. Meu benefício com o rastreamento provavelmente é suficiente para justificar fazê-lo, mas não tenho certeza.",
              value:
                "Talvez. Meu benefício com o rastreamento provavelmente é suficiente para justificar fazê-lo, mas não tenho certeza.",
            },
            {
              label:
                "Talvez não. Meu benefício com o rastreamento é pequeno e não tenho certeza se justifica fazê-lo.",
              value:
                "Talvez não. Meu benefício com o rastreamento é pequeno e não tenho certeza se justifica fazê-lo.",
            },
            {
              label:
                "Não. Meu benefício com o rastreamento é muito pequeno para justificar fazê-lo.",
              value:
                "Não. Meu benefício com o rastreamento é muito pequeno para justificar fazê-lo.",
            },
          ],
        },
        formRecommended: {
          label: "Eu fui:",
          placeholder: "",
          options: [
            {
              label: "Recomendado a fazer o rastreamento",
              value: "Recomendado a fazer o rastreamento",
            },
            {
              label: "Não recomendado a fazer o rastreamento",
              value: "Não recomendado a fazer o rastreamento",
            },
          ],
        },
        formRisk: {
          label: "Meu risco foi",
          placeholder: "1%",
        },
        formReason: {
          label: "Minhas razões são:",
          placeholder: "",
        },
      },
    },
    coveragePage: {
      banner_image_source: "/image/bannerImage_coveragePage.png",
      banner_title: "Cobertura de seguro para rastreamento de câncer de pulmão",
      title:
        "Clique na opção que se aplica a você para ver se o rastreamento de câncer de pulmão é coberto pelo seu plano de saúde.",
      sub_title:
        "Nota: Como as recomendações da Força-Tarefa de Serviços Preventivos dos EUA (US Preventive Services Task Force) foram atualizadas em 9 de março de 2021, a cobertura de seguro ainda não reflete essas alterações. Entre em contato com seus provedores de seguro para confirmar se o rastreamento de câncer de pulmão seria coberto para você caso: i) tenha entre 50 e 54 anos com um histórico de tabagismo de mais de 20 anos-maço; ou ii) tenha entre 55 e 80 anos com um histórico de tabagismo de 20-29 anos-maço.",
      condition_list: [
        {
          title: "Plano patrocinado pelo empregador",
          introtext:
            "Este é o seguro fornecido a você por meio do seu empregador, sindicato ou outra associação profissional. Você tem direito à cobertura para o rastreamento inicial de câncer de pulmão se:",
          criteria_list: [
            "tiver entre 50 e 80 anos",
            <div>
              tiver um histórico de tabagismo de 20 anos-maço (Clique&nbsp;
              <Link
                to={"/Portuguese/calculadora-de-anos-maco"}
                style={{ width: "100%", textDecoration: "none" }}
                data-on="click"
                data-event-category="CoveragePage"
                data-event-action={"/Portuguese/calculadora-de-anos-maco"}
              >
                aqui&nbsp;
              </Link>
              para calcular seus anos-maço)
            </div>,
            "tiver fumado nos últimos 15 anos",
          ],

          note: "Se seu plano for 'grandfathered' sob o Affordable Care Act, ele não é obrigado a cobrir o rastreamento de câncer de pulmão. Verifique os documentos do seu plano e confirme com sua seguradora sobre isso.",
          question_answer_list: [
            {
              question: "Deveria haver compartilhamento de custos?",
              answer:
                "Não. Você não deve ser cobrado com copay, co-seguro ou franquia para o rastreamento, se for a um prestador que esteja na sua rede.",
            },
            {
              question: "Haverá outros custos?",
              answer:
                "Pode haver. Certifique-se de verificar com sua seguradora o que eles estão cobrindo pelo procedimento.",
            },
          ],
        },
        {
          title: "Plano do mercado de seguros estadual",
          introtext:
            "Este é um plano em que você se inscreveu via healthcare.gov ou por meio de um site de mercado/troca criado pelo seu estado. Você tem direito à cobertura para o rastreamento inicial de câncer de pulmão se:",
          criteria_list: [
            "tiver entre 50 e 80 anos",
            <div>
              tiver um histórico de tabagismo de 20 anos-maço (Clique&nbsp;
              <Link
                to={"/Portuguese/calculadora-de-anos-maco"}
                style={{ width: "100%", textDecoration: "none" }}
                data-on="click"
                data-event-category="CoveragePage"
                data-event-action={"/Portuguese/calculadora-de-anos-maco"}
              >
                aqui
              </Link>{" "}
              para calcular seus anos-maço)
            </div>,
            "tiver fumado nos últimos 15 anos",
          ],
          question_answer_list: [
            {
              question: "Deveria haver compartilhamento de custos?",
              answer:
                "Não. Você não deve ser cobrado com copay, co-seguro ou franquia para o rastreamento, se for a um prestador que esteja na sua rede.",
            },
            {
              question: "Haverá outros custos?",
              answer:
                "Pode haver. Certifique-se de verificar com sua seguradora o que eles estão cobrindo pelo procedimento.",
            },
          ],
        },
        {
          title: "Medicare",
          introtext:
            "Você tem direito à cobertura para o rastreamento inicial de câncer de pulmão se:",
          criteria_list: [
            "tiver entre 50 e 77 anos",
            <div>
              tiver um histórico de tabagismo de 20 anos-maço (Clique&nbsp;
              <Link
                to={"/Portuguese/calculadora-de-anos-maco"}
                style={{ width: "100%", textDecoration: "none" }}
                data-on="click"
                data-event-category="CoveragePage"
                data-event-action={"/Portuguese/calculadora-de-anos-maco"}
              >
                aqui
              </Link>{" "}
              para calcular seus anos-maço)
            </div>,
            "tiver fumado nos últimos 15 anos",
            "não apresentar sinais ou sintomas (por exemplo, dor, perda de peso, tosse persistente) de câncer de pulmão",
          ],
          question_answer_list: [
            {
              question: "Deveria haver compartilhamento de custos?",
              answer:
                "Não. Você não deve ser cobrado com copay, co-seguro ou franquia para o rastreamento, se for a um prestador que esteja na sua rede.",
            },
            {
              question: "Haverá outros custos?",
              answer:
                "Pode haver. Certifique-se de verificar com sua seguradora o que eles estão cobrindo pelo procedimento.",
            },
          ],
        },
        {
          title: "Medicaid",
          introtext:
            "A cobertura do rastreamento de câncer de pulmão no Medicaid varia de estado para estado. Sugerimos que você entre em contato com seu provedor do Medicaid e verifique se seu plano cobre o rastreamento de câncer de pulmão.",
          criteria_list: [],
          note: "",
          question_answer_list: [],
        },
        {
          title: "Plano individual",
          introtext:
            "Este é o seguro que você compra diretamente de uma seguradora. Você tem direito à cobertura para o rastreamento inicial de câncer de pulmão se você:",
          criteria_list: [
            "tiver entre 50 e 80 anos",
            <div>
              tiver um histórico de tabagismo de 20 anos-maço (Clique&nbsp;
              <Link
                to={"/Portuguese/calculadora-de-anos-maco"}
                style={{ width: "100%", textDecoration: "none" }}
                data-on="click"
                data-event-category="CoveragePage"
                data-event-action={"/Portuguese/calculadora-de-anos-maco"}
              >
                aqui
              </Link>{" "}
              para calcular seus anos-maço)
            </div>,
            "tiver fumado nos últimos 15 anos",
          ],

          note: "Se seu plano for 'grandfathered' sob o Affordable Care Act, ele não é obrigado a cobrir o rastreamento de câncer de pulmão. Verifique os documentos do seu plano e confirme com sua seguradora sobre isso.",
          question_answer_list: [
            {
              question: "Deveria haver compartilhamento de custos?",
              answer:
                "Não. Você não deve ser cobrado com copay, co-seguro ou franquia para o rastreamento, se for a um prestador que esteja na sua rede.",
            },
            {
              question: "Haverá outros custos?",
              answer:
                "Pode haver. Certifique-se de verificar com sua seguradora o que eles estão cobrindo pelo procedimento.",
            },
          ],
        },
      ],
      citation_text: (
        <div>
          <p>
            Clique&nbsp;
            <a
              target="_blank"
              href="https://www.lung.org/lung-health-diseases/lung-disease-lookup/lung-cancer/saved-by-the-scan/resources/is-lung-cancer-screening-right"
              rel="noopener noreferrer"
            >
              aqui&nbsp;
            </a>
            para obter as informações mais atualizadas sobre cobertura de seguro
            da Associação Americana do Pulmão (American Lung Association).
          </p>
        </div>
      ),
    },
    savedPage: {
      title: "Aqui estão os materiais que você salvou",
      item_unit: "trecho(s)",
      print_button_label: "Imprimir",
    },
    publicationPage: {
      title: "Publicações de Pesquisa",
      publication_list: [
        {
          category: "journal",
          text: (
            <span>
              Yan Kwan Lau, Harihar Bhattarai, Tanner J Caverly, Pei-Yao Hung,
              Evelyn Jimenez-Mendoza, Minal R Patel, Michele L Coté, Douglas A
              Arenberg, Rafael Meza (2021). Conhecimento, percepções e tomada de
              decisão sobre rastreamento de câncer de pulmão entre
              afro-americanos em Detroit, Michigan.{" "}
              <i>American Journal of Preventive Medicine (AJPM)</i>, Volume 60,
              Edição 1, Páginas e1-e8
            </span>
          ),
          year: 2021,
          description:
            "Testamos se o shouldiscreen.com ajudou a melhorar o conhecimento das pessoas sobre riscos de câncer de pulmão e rastreamento de câncer de pulmão, e se diminuiu seu conflito decisório no leste de Detroit, Michigan.",
          links: [
            {
              label: "Link",
              link: "https://pubmed.ncbi.nlm.nih.gov/33341184/",
            },
          ],
        },
        {
          category: "conference",
          text: (
            <span>
              Pei-Yao Hung, Yan Kwan Lau, Mark S. Ackerman, Rafael Meza (2019).
              Projetando uma ferramenta de decisão baseada na Web para que
              indivíduos considerem o rastreamento de câncer de pulmão.{" "}
              <i>
                13th EAI International Conference on Pervasive Computing
                Technologies for Healthcare (PervasiveHealth)
              </i>
              , Trento, Itália, 20-23 de maio. pp 51-60
            </span>
          ),
          year: 2019,
          description:
            "Realizamos oficinas de design participativo e grupos focais para fornecer sugestões de design para ferramentas de decisão e orientar o desenvolvimento do shouldiscreen.com.",
          links: [
            {
              label: "Link",
              link: "https://doi.org/10.1145/3329189.3329210",
            },
          ],
        },
        {
          category: "journal",
          text: (
            <span>
              Yan Kwan Lau, Tanner J. Caverly, Pianpian Cao, Sarah T. Cherng,
              Mindy West, Charles Gaber, Douglas Arenberg, Rafael Meza (2015).
              Avaliação de uma Ferramenta de Decisão Personalizada baseada na
              Web para o Rastreamento de Câncer de Pulmão.{" "}
              <i>American Journal of Preventive Medicine (AJPM)</i>, Volume 49,
              Edição 6, Páginas e125–e129
            </span>
          ),
          year: 2015,
          description:
            "Testamos se o shouldiscreen.com ajudou a melhorar o conhecimento das pessoas sobre riscos de câncer de pulmão e rastreamento de câncer de pulmão, e se diminuiu seu conflito decisório.",
          links: [
            {
              label: "Link",
              link: "https://www.ncbi.nlm.nih.gov/pmc/articles/PMC5544524/",
            },
          ],
        },
        {
          category: "journal",
          text: (
            <span>
              Yan Kwan Lau, Tanner J Caverly, Sarah T Cherng, Pianpian Cao,
              Mindy West, Douglas Arenberg, e Rafael Meza (2014).
              Desenvolvimento e Validação de uma Ferramenta de Decisão
              Personalizada baseada na Web para o Rastreamento de Câncer de
              Pulmão usando Métodos Mistos: Um Protocolo de Estudo.{" "}
              <i>JMIR Research Protocol</i>
            </span>
          ),
          year: 2014,
          description:
            "Este é um protocolo que descreve as várias fases dos planos de pesquisa e desenvolvimento do shouldiscreen.com.",
          links: [
            {
              label: "Link",
              link: "https://www.ncbi.nlm.nih.gov/pmc/articles/PMC4376198/",
            },
          ],
        },
      ],
    },
    teamPage: {
      title: "Os desenvolvedores do shouldiscreen.com",

      investigator_title: "PESQUISADORES PRINCIPAIS",

      investigator_list: [
        {
          name: "Rafael Meza, PhD",
          affiliation: [
            "Department of Epidemiology, School of Public Health, University of Michigan",
          ],
          link: "https://sph.umich.edu/faculty-profiles/meza-rafael.html",
        },
        {
          name: "Douglas Arenberg, MD",
          affiliation: [
            "Department of Internal Medicine, University of Michigan",
          ],
          link: "https://uofmhealth.org/profile/286/douglas-allen-arenberg-md",
        },
        {
          name: "Yan Kwan Lau, PhD, MPH",
          affiliation: [
            "Department of Epidemiology, School of Public Health, University of Michigan",
          ],
          link: "",
        },
        {
          name: "Tanner Caverly, MD, MPH ",
          affiliation: [
            "Center for Clinical Management Research, Veterans Affairs, Ann Arbor Healthcare Systems; Department of Internal Medicine, University of Michigan",
          ],
          link: "http://medicine.umich.edu/dept/lhs/tanner-caverly-md-mph",
        },
      ],
      research_development_list: [
        {
          name: "Pianpian Cao, MPH",
          affiliation: [
            "Departamento de Epidemiologia, Escola de Saúde Pública, Universidade de Michigan",
          ],
          link: "https://sph.umich.edu/epid/phdstudents/cao_pianpian.html",
        },
        {
          name: "Sarah Cherng, PhD, MPH",
          affiliation: [
            "Departamento de Epidemiologia, Escola de Saúde Pública, Universidade de Michigan",
          ],
          link: "http://www.sarahcherng.com/",
        },
        {
          name: "Pei-Yao Hung, PhD, MSI",
          affiliation: ["Escola de Informação, Universidade de Michigan"],
          link: "https://peiyaoh.github.io/",
        },
        {
          name: "Mindy West, MPH",
          affiliation: [
            "Departamento de Epidemiologia, Escola de Saúde Pública, Universidade de Michigan",
          ],
          link: "",
        },
      ],

      press_title: "COMUNICADOS DE IMPRENSA",

      press_resources_list: [
        {
          link: "https://www.healthcanal.com/cancers/lung-cancer/68111-tool-helps-patients-providers-decide-if-lung-cancer-screening-is-warranted.html",
          label:
            "Ferramenta ajuda pacientes a decidirem se o rastreamento de câncer de pulmão é apropriado",
          source_label: "Health Canal",
        },
        {
          link: "http://www.reuters.com/article/us-health-screening-lung-cancer-idUSKCN0SF2LQ20151021#f3dQjQZuWa652jvR.97",
          label:
            "Módulo web ajuda pacientes a decidirem sobre rastreamento de câncer de pulmão",
          source_label: "Reuters",
        },
        {
          link: "https://www.nytimes.com/2015/05/12/health/on-medicare-and-assessing-the-value-of-lung-cancer-screening.html",
          label:
            "Sobre Medicare e avaliando o valor do rastreamento de câncer de pulmão",
          source_label: "New York Times",
        },
        {
          link: "https://news.umich.edu/tool-helps-patients-providers-decide-if-lung-cancer-screening-is-warranted/",
          label:
            "Ferramenta ajuda pacientes e profissionais a decidirem se o rastreamento de câncer de pulmão é apropriado",
          source_label: "University of Michigan News",
        },
      ],
      founding_title: "APOIO FINANCEIRO",
      founding_content:
        "O desenvolvimento desta ferramenta foi apoiado pelo Elizabeth A Crary Fund do Centro de Câncer da Universidade de Michigan (2014-2015) e pelo Rogel Cancer Center da Universidade de Michigan, Programa de Pesquisa em Controle e Ciências da População do Câncer: Outreach and Health Disparities Grant.",
    },
    historyPage: {
      title: "O histórico de desenvolvimento de shouldiscreen.com",
      description: (
        <Fragment>
          <p>
            Shouldiscreen.com é uma ferramenta de decisão para rastreamento de
            câncer de pulmão que é projetada, avaliada e redesenhada
            continuamente com base na literatura existente, no esforço
            multidisciplinar de pesquisa, em sugestões de especialistas em saúde
            e no feedback de usuários.
          </p>
          <p>
            Nosso objetivo é melhorá-la continuamente, mantendo-a atualizada com
            o conhecimento atual sobre rastreamento de câncer de pulmão e
            refinando-a com metodologia centrada no ser humano para que as
            pessoas possam facilmente obter uma melhor compreensão do
            conhecimento científico relevante e das melhores práticas.
          </p>
          <p>
            Aqui delineamos uma lista de marcos e mudanças no desenvolvimento de
            shouldicreen.com.
          </p>
        </Fragment>
      ),
      eventList: [
        {
          label: "Junho de 2014",
          description:
            "Primeira versão do shouldiscreen criada para feedback de usuários potenciais e especialistas em comunicação de risco em saúde.",
          timestamp: "2014-06-01T00:00:00",
          marker_color: "is-warning",
          marker_type: "is-icon",
          marker_icon_type: "fas fa-play",
        },
        {
          label: "Janeiro de 2015",
          description: "Versão 1 do shouldiscreen.com oficialmente implantada",
          timestamp: "2015-01-01T00:00:00",
          marker_color: "is-success",
          marker_type: "is-icon",
          marker_icon_type: "fa fa-flag",
        },
        {
          label: "Setembro de 2017",
          description: "Habilitado suporte bilíngue com conteúdo em espanhol.",
          timestamp: "2017-09-01T00:00:00",
          marker_color: "is-danger",
          marker_type: "is-icon",
          marker_icon_type: "fa fa-book",
        },
        {
          label: "Julho de 2018",
          description: (
            <Fragment>
              Adicionada cobertura de seguro com base em observações e feedback
              de estudos de design participativo e grupos focais (veja{" "}
              <a
                target="_blank"
                href="https://dl.acm.org/doi/10.1145/3329189.3329210"
                rel="noopener noreferrer"
              >
                link
              </a>
              ).
            </Fragment>
          ),
          timestamp: "2018-07-01T00:00:00",
          marker_color: "is-link",
        },
        {
          label: "Setembro de 2018",
          description: (
            <Fragment>
              Adicionado recurso de salvar para mais tarde, permitindo que as
              pessoas coletem conteúdo relevante baseado em conversas com
              clínicos que conduzem tomada de decisão compartilhada com
              pacientes.
            </Fragment>
          ),
          timestamp: "2018-09-01T00:00:00",
          marker_color: "is-link",
        },
        {
          label: "Setembro de 2018",
          description: (
            <Fragment>
              Adicionado um menu pop-up para que as pessoas forneçam feedback
              (útil/não útil) sobre o conteúdo.
            </Fragment>
          ),
          timestamp: "2018-09-15T00:00:00",
          marker_color: "is-primary",
        },
        {
          label: "Dezembro de 2019",
          description: (
            <Fragment>
              Adicionado um menu de navegação baseado em perguntas com base em
              observações de estudos de design participativo e grupos focais
              (veja{" "}
              <a
                target="_blank"
                href="https://dl.acm.org/doi/10.1145/3329189.3329210"
                rel="noopener noreferrer"
              >
                link
              </a>
              ).
            </Fragment>
          ),
          timestamp: "2019-12-01T00:00:00",
          marker_color: "is-link",
        },
        {
          label: "Maio de 2019",
          description: (
            <Fragment>
              Apresentamos nossa pesquisa de design participativo e grupos
              focais sobre o design do shouldiscreen.com na{" "}
              <i>
                13ª Conferência Internacional EAI sobre Tecnologias Pervasivas
                para Saúde
              </i>{" "}
              (PervasiveHealth 2019)(veja{" "}
              <a
                target="_blank"
                href="https://dl.acm.org/doi/10.1145/3329189.3329210"
                rel="noopener noreferrer"
              >
                link
              </a>
              ).
            </Fragment>
          ),
          timestamp: "2019-05-21T00:00:00",
          marker_color: "is-warning",
        },
        {
          label: "Dezembro de 2019",
          description: (
            <Fragment>
              Critérios de elegibilidade para rastreamento atualizados com
              Medicare.
            </Fragment>
          ),
          timestamp: "2019-12-12T00:00:00",
          marker_color: "is-primary",
        },
        {
          label: "Janeiro de 2020",
          description: (
            <Fragment>
              Habilitada personalização de mensagens com base no risco calculado
              de câncer de pulmão e informações biográficas.
            </Fragment>
          ),
          timestamp: "2020-01-01T00:00:00",
          marker_color: "is-primary",
        },
        {
          label: "Agosto de 2020",
          description: (
            <Fragment>
              Adicionadas várias opções de visualização para ajudar as pessoas a
              entender os benefícios e riscos do rastreamento.
            </Fragment>
          ),
          timestamp: "2020-08-22T00:00:00",
          marker_color: "is-primary",
        },
        {
          label: "Novembro de 2020",
          description: (
            <Fragment>
              Conteúdo revisado para refletir descobertas na revisão de
              evidências USPSTF (2020) em relação a danos de falsos positivos ao
              usar LungRADS (veja{" "}
              <a
                target="_blank"
                href="https://www.uspreventiveservicestaskforce.org/uspstf/document/draft-evidence-review/lung-cancer-screening1"
                rel="noopener noreferrer"
              >
                link
              </a>
              ).
            </Fragment>
          ),
          timestamp: "2020-11-21T00:00:00",
          marker_color: "is-primary",
        },
        {
          label: "Março de 2021",
          description: (
            <Fragment>
              Conteúdo revisado para refletir as recomendações USPSTF (2021) de
              reduzir as faixas etárias e os parâmetros de pack-year para
              rastreamento de câncer de pulmão (veja{" "}
              <a
                target="_blank"
                href="https://www.uspreventiveservicestaskforce.org/uspstf/recommendation/lung-cancer-screening"
                rel="noopener noreferrer"
              >
                link
              </a>
              ).
            </Fragment>
          ),
          timestamp: "2021-03-09T00:00:00",
          marker_color: "is-danger",
          marker_type: "is-icon",
          marker_icon_type: "fas fa-info-circle",
        },
        {
          label: "Janeiro de 2022",
          description: "Habilitado suporte multilíngue com conteúdo em chinês.",
          timestamp: "2022-01-29T00:00:00",
          marker_color: "is-danger",
          marker_type: "is-icon",
          marker_icon_type: "fa fa-flag",
        },
      ],
    },
    noPage: {
      title: "Should I Screen: não encontrado",
      not_found_message:
        "Você chegou aqui por engano ou há um problema em nosso site. Clique no link a seguir para voltar à página inicial.",
      take_me_home_label: "Leve-me para a página inicial.",
      take_me_home_link: "/Portuguese/inicio",
    },
    footerComponent: {
      development_team_content: (
        <p>
          Criado pela &nbsp;
          <Link
            to={"/Portuguese/equipe-de-desenvolvimento"}
            className="my-link"
            data-on="click"
            data-event-category="Navigation"
            data-event-action={"team"}
          >
            Universidade de Michigan
          </Link>
          &nbsp; usando o modelo de previsão de risco desenvolvido por Tammemägi
          et al. (2013).&nbsp;
          <a
            target="_blank"
            href="https://www.nejm.org/doi/full/10.1056/NEJMoa1211776"
            rel="noopener noreferrer"
            className="my-link"
          >
            Critérios de seleção para rastreamento de câncer de pulmão
          </a>
          .&nbsp;<em>New England Journal of Medicine</em>, 368(8): 728-736,
          2013.
        </p>
      ),
      basis_content: (
        <p>
          Veja{" "}
          <Link
            to={"/Portuguese/publicacoes"}
            data-on="click"
            data-event-category="Navigation"
            data-event-action={"publications"}
            className="my-link"
          >
            publicações
          </Link>{" "}
          para artigos revisados por pares sobre o design, desenvolvimento e
          validação desta ferramenta de decisão.
        </p>
      ),

      feedback_content: (
        <p>
          Seu feedback é valioso para nós. Por favor&nbsp;
          <Link to={"/Portuguese/feedback"} className="my-link">
            preencha esta pesquisa
          </Link>
          &nbsp;para nos ajudar a melhorar o site.&nbsp;
        </p>
      ),

      disclaimer_content: (
        <p>
          <Link to={"/Portuguese/aviso-legal"} className="my-link">
            Disclaimer
          </Link>
          . &nbsp;
          <em>
            O conteúdo escrito deste site é compartilhado sob &nbsp;
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="http://creativecommons.org/licenses/by-nc-sa/4.0/"
              className="my-link"
            >
              CC BY-NC-SA 4.0
            </a>
            .&nbsp;
          </em>
          Todas as imagens neste site são material protegido por direitos
          autorais e todos os direitos são reservados, a menos que indicado de
          outra forma na página.
        </p>
      ),
    },
    lastUpdateComponent: {
      message_head: "Esta página foi atualizada pela última vez em ",
      pointer_to_history: (
        <Fragment>
          Veja{" "}
          <Link
            to={"/Portuguese/historico-de-desenvolvimento"}
            data-on="click"
            data-event-category="UpdateComponent"
            data-event-action={"/Portuguese/historico-de-desenvolvimento"}
            className="my-link"
          >
            o histórico de alterações
          </Link>
          .
        </Fragment>
      ),
    },
    headerComponent: {
      title: "Sobre Câncer de Pulmão & Rastreamento",
      menu_title: " Menu",
      route_in_menu_limit: 8,
      route_order_list: [
        {
          route: "/Portuguese/inicio",
          label: "Início",
          title:
            "Should I Screen: ferramenta de decisão para rastreamento de câncer de pulmão",
          page: "HomePage",
          description:
            "O rastreamento de câncer de pulmão usa tomografia computadorizada de baixa dose (TCBD) para encontrar o câncer de pulmão em estágio inicial. Aqui estão informações fornecidas por médicos para ajudá-lo a decidir.",
          hashTagList: ["shouldiscreen", "pulmão", "câncer", "triagem", "saúde"],
        },
        {
          route: "/Portuguese/como-e-realizado-o-rastreamento",
          label: "O que é rastreamento de câncer de pulmão",
          title: "O que é rastreamento de câncer de pulmão",
          page: "HowPage",
          hashTagList: ["shouldiscreen", "pulmão", "câncer", "triagem", "saúde"],
        },
        {
          route: "/Portuguese/cobertura",
          label: "Cobertura de seguro",
          title: "Cobertura de seguro para rastreamento de câncer de pulmão",
          page: "CoveragePage",
          hashTagList: ["shouldiscreen", "pulmão", "câncer", "triagem", "saúde"],
        },
        {
          route: "/Portuguese/beneficios-e-riscos-do-rastreamento",
          label: "Benefícios e danos do rastreamento",
          title: "Benefícios e danos do rastreamento de câncer de pulmão",
          page: "BenefitHarmPage",
          hashTagList: ["shouldiscreen", "pulmão", "câncer", "triagem", "saúde"],
        },
        {
          route: "/Portuguese/causas-do-cancer-de-pulmao",
          label: "Causas do câncer de pulmão",
          title: "Causas do câncer de pulmão",
          page: "CausePage",
          hashTagList: ["shouldiscreen", "pulmão", "câncer", "triagem", "saúde"],
        },
        {
          route: "/Portuguese/calculadora-de-anos-maco",
          label: "Calculadora de ano-maço (pack-year)",
          title: "Calcular ano-maço (pack-year)",
          page: "PackPage",
          hashTagList: ["shouldiscreen", "pulmão", "câncer", "triagem", "saúde"],
        },
        {
          route: "/Portuguese/reducao-de-risco-de-cancer-de-pulmao",
          label: "Reduzindo o risco de câncer de pulmão",
          title: "Reduzindo o risco de câncer de pulmão",
          page: "RiskPage",
          hashTagList: ["shouldiscreen", "pulmão", "câncer", "triagem", "saúde"],
        },
        {
          route: "/Portuguese/calculadora-de-risco-de-cancer-de-pulmao" ,
          label: "Calcular meu risco de câncer de pulmão",
          title: "Calcular meu risco de câncer de pulmão",
          page: "CalculatePage",
          hashTagList: ["shouldiscreen", "pulmão", "câncer", "triagem", "saúde"],
        },
        {
          route: "/Portuguese/resultado",
          label: "Resultado",
          title: "Qual é o meu risco de câncer de pulmão",
          page: "ResultPage",
          hashTagList: ["shouldiscreen", "pulmão", "câncer", "triagem", "saúde"],
        },
        {
          route: "/Portuguese/aviso-legal",
          label: "Disclaimer",
          title: "Disclaimer",
          page: "DisclaimerPage",
          hashTagList: ["shouldiscreen", "pulmão", "câncer", "triagem", "saúde"],
        },
        {
          route: "/Portuguese/equipe-de-desenvolvimento",
          label: "Equipe de Desenvolvimento",
          title: "Equipe de Desenvolvimento",
          page: "TeamPage",
          hashTagList: ["shouldiscreen", "pulmão", "câncer", "triagem", "saúde"],
        },
        {
          route: "/Portuguese/historico-de-desenvolvimento",
          label: "Histórico de Desenvolvimento",
          title: "Histórico de Desenvolvimento",
          page: "HistoryPage",
          hashTagList: ["shouldiscreen", "pulmão", "câncer", "triagem", "saúde"],
        },
        {
          route: "/Portuguese/feedback",
          label: "Feedback",
          title: "Feedback",
          page: "FeedbackPage",
          hashTagList: ["shouldiscreen", "pulmão", "câncer", "triagem", "saúde"],
        },
        {
          route: "/Portuguese/saved",
          label: "Salvo",
          title: "Conteúdo Salvo",
          page: "SavedPage",
          hashTagList: ["shouldiscreen", "pulmão", "câncer", "triagem", "saúde"],
        },
        {
          route: "/Portuguese/publicacoes",
          label: "Publicações",
          title: "Publicações",
          page: "PublicationPage",
          hashTagList: ["shouldiscreen", "pulmão", "câncer", "triagem", "saúde"],
        },
      ],
    },
    savedComponent: {
      saved_label: "Salvo",
      saved_url: "/Portuguese/saved",
    },
  };
}



