import React, { Component, Suspense } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";

//import Header from "./components/Header";
//import Flexbox from "flexbox-react";
//import Sticky from "react-stickynode";
//import ComparePage from "./pages/ComparePage";
//import Button from 'react-bootstrap/lib/Button';
//import Glyphicon from 'react-bootstrap/lib/Glyphicon';
// extra features
//import annotator from "annotator";
//import lightrange from "lightrange";
//import DeviceDetector from "device-detector-js";

import NotificationSystem from "react-notification-system";
import {Popover} from "react-bootstrap";
import ServerService from "./ServerService";
import MyUtility from "./MyUtility";

import {
  deviceDetect,
  isBrowser,
  isTablet,
  isMobileOnly,
  isIE
} from "react-device-detect";

// variables
import Content from "./Content";
import Variable from "./Variable";
//import 'bootstrap/dist/css/bootstrap.min.css';
import "./css/app.css";
// now all the css
/*
import './css/app.css';
import './css/fontawesome_all.css';
import './css/grid.min.css';
import './css/my-react-accessible-accordion.css'
import './css/print.min.css'
//import './css/bootstrap.min.css';
*/

// pages
const HomePage = React.lazy(() => import("./pages/HomePage"));
const HowPage = React.lazy(() => import("./pages/HowPage"));
const BenefitHarmPage = React.lazy(() => import("./pages/BenefitHarmPage"));
const CausePage = React.lazy(() => import("./pages/CausePage"));
const PackPage = React.lazy(() => import("./pages/PackPage"));
const RiskPage = React.lazy(() => import("./pages/RiskPage"));
const CalculatePage = React.lazy(() => import("./pages/CalculatePage"));
const ResultPage = React.lazy(() => import("./pages/ResultPage"));
const DisclaimerPage = React.lazy(() => import("./pages/DisclaimerPage"));
const TeamPage = React.lazy(() => import("./pages/TeamPage"));
const FeedbackPage = React.lazy(() => import("./pages/FeedbackPage"));
const NoPage = React.lazy(() => import("./pages/NoPage"));
const CoveragePage = React.lazy(() => import("./pages/CoveragePage"));
const SavedPage = React.lazy(() => import("./pages/SavedPage"));
const PublicationPage = React.lazy(() => import("./pages/PublicationPage"));
const HistoryPage = React.lazy(() => import("./pages/HistoryPage"));
const Footer = React.lazy(() => import("./components/Footer"));
const LastUpdate = React.lazy(() => import("./components/LastUpdate"));

//import HomePage from "./pages/HomePage";
//import HowPage from "./pages/HowPage";
//import BenefitHarmPage from "./pages/BenefitHarmPage";
//import CausePage from "./pages/CausePage";
//import PackPage from "./pages/PackPage";
//import RiskPage from "./pages/RiskPage";
//import CalculatePage from "./pages/CalculatePage";
//import ResultPage from "./pages/ResultPage";
//import DisclaimerPage from "./pages/DisclaimerPage";
//import TeamPage from "./pages/TeamPage";
//import FeedbackPage from "./pages/FeedbackPage";
//import NoPage from "./pages/NoPage";
//import CoveragePage from "./pages/CoveragePage";
//import SavedPage from "./pages/SavedPage";
//import PublicationPage from "./pages/PublicationPage";
//import HistoryPage from "./pages/HistoryPage";

//import Footer from "./components/Footer";
// import LastUpdate from "./components/LastUpdate";

const containerStyle = {
  backgroundColor: "#ff0000",
};

// <Route path="/:id" component={Child}/>
const mainContentStyle = {
  width: "70%",
  minHeight: "60vh",
};

const noOverflow = {
  overflow: "visible",
};

//Random: before doing yarn eject to allow relative path options (kind of dangerous)

export default class App extends Component {
  constructor(props) {
    super(props);

    var _notificationSystem = null;

    // device-detector-js
    /*
    const deviceDetector = new DeviceDetector();
    console.log("userAgent: " + navigator.userAgent);
    const userAgentInfo = deviceDetector.parse(navigator.userAgent);
    console.log(userAgentInfo);
    
    console.log(" userAgentInfo.device.type: " + userAgentInfo.device.type);
    */

    // react-device-detect
    console.log("App: deviceDetect: " + JSON.stringify(deviceDetect()));

    this.changeLanguage = this.changeLanguage.bind(this);

    this.getDefaultURL = this.getDefaultURL.bind(this);

    this.onMouseUp = this.onMouseUp.bind(this);
    this.onSaveButtonDown = this.onSaveButtonDown.bind(this);
    this.onHelpfulButtonDown = this.onHelpfulButtonDown.bind(this);
    this.onUnHelpfulButtonDown = this.onUnHelpfulButtonDown.bind(this);
    //this.onSaveContentBadgeClick = this.onSaveContentBadgeClick.bind(this);

    this.clearSelection = this.clearSelection.bind(this);

    this.extractAnnotation = this.extractAnnotation.bind(this);
    this.saveAnnotation = this.saveAnnotation.bind(this);
    this.submitAnnotationToDB = this.submitAnnotationToDB.bind(this);

    this.addNotification = this.addNotification.bind(this);

    let dType = "";
    if (isBrowser) {
      dType = "desktop";
    } else if (isTablet) {
      dType = "tablet";
    } else if (isMobileOnly) {
      dType = "mobile";
    }

    this.state = {
      language: "English",
      deviceType: dType,
      needPopover: false,
      popoverLeft: 0,
      popoverTop: 0,
      showSavedBadge: false,
      savedContent: [],
    };

    // for annotator
    /*
    var annotatorApp = new annotator.App();
    annotatorApp.include(annotator.ui.main);
    annotatorApp.include(annotator.storage.http);
    annotatorApp
    .start()
    .then(function () {
        console.log("annotator started");
        alert("annotator started");
        // load annotations
        //app.annotations.load();
    });
    */
  }

  changeLanguage(item) {
    console.log("App.changeLanguage: " + item);
    this.setState({
      language: item,
    });
  }

  addNotification(message, level = "info", position = "tr", autoDismiss = 3, children=undefined) {
    // Available: success, error, warning and info
    // Position of the notification. Available: tr (top right), tl (top left), tc (top center), br (bottom right), bl (bottom left), bc (bottom center)
    this._notificationSystem.addNotification({
      message: message,
      level: level,
      position: position,
      autoDismiss: autoDismiss,
      children: children
    });
  }

  getDefaultURL() {
    const { language } = this.state;

    let displayLanguage = "";

    var defaultHomeURL = "";

    if (language == undefined) {
      displayLanguage = "English";
    } else {
      displayLanguage = language;
    }

    //alert("Language: " + displayLanguage);

    

    switch (displayLanguage) {
      case "Español":
        defaultHomeURL = "/Español/inicio";
        break;
      default:
        defaultHomeURL = `/${displayLanguage}/home`;
        break;
    }

    return defaultHomeURL;
  }

  clearSelection() {
    var selection = null;
    if (window.getSelection) {
      selection = window.getSelection();
    } else if (document.selection) {
      selection = document.selection;
    }
    if (selection) {
      if (selection.empty) {
        selection.empty();
      }
      if (selection.removeAllRanges) {
        selection.removeAllRanges();
      }
    }
  }

  extractAnnotation(event) {
    console.log("extractAnnotation");
    const { savedContent } = this.state;

    var result = {};

    var text = "";

    if (window.getSelection) {
      text = window.getSelection().toString();
    } else if (document.selection && document.selection.type != "Control") {
      text = document.selection.createRange().text;
    }

    if (text.length > 0) {
      // save the text
      let newSavedContent = savedContent;

      result = {
        title: document.title,
        pathname: window.location.pathname,
        content: text,
      };
    } else {
      result = null;
    }

    return result;
  }

  submitAnnotationToDB(annotationObj) {
    console.log("submitAnnotationToDB");
    // not sure if this will work
    const { language } = this.state;

    var cached_feedback_id = Variable.getValue("feedback_id");
    var cached_pack_id = Variable.getValue("pack_id");
    var cached_risk_id = Variable.getValue("risk_id");
    var cached_reflection_id = Variable.getValue("reflection_id");
    var cached_session_id = Variable.getValue("session_id");

    return ServerService.insertAnnotation(
      annotationObj,
      cached_feedback_id,
      cached_pack_id,
      cached_reflection_id,
      cached_risk_id,
      cached_session_id,
      language
    );
    /*
    .then(response => {
      console.log(response);

      // now, can we somehow update that annotation object???
      
    })
    .catch(error => {
      console.log(error);
    });
    */
  }

  saveAnnotation(annotationObj) {
    console.log("saveAnnotation");
    const { savedContent, deviceType } = this.state;

    // not sure if this will work
    const { language } = this.state;

    //var deviceInfo = JSON.stringify(deviceDetect(), null, 2);

    var cached_feedback_id = Variable.getValue("feedback_id");
    var cached_risk_id = Variable.getValue("risk_id");
    var cached_reflection_id = Variable.getValue("reflection_id");
    var cached_session_id = Variable.getValue("session_id");

    if (cached_session_id == "variable default") {
      // no session id yet
      ServerService.insertSession(
        //deviceInfo,
        cached_feedback_id,
        cached_reflection_id,
        cached_risk_id,
        language
      )
        .then((response) => {
          console.log(response);

          console.log(
            "Get Session: " + JSON.stringify(response.data.insert_id)
          );
          Variable.setValue("session_id", response.data.insert_id);
          console.log(
            "After insert, session_id = " + Variable.getValue("session_id")
          );

          // now, submit the annotation to the database

          annotationObj.sessionID = response.data.insert_id;

          // ok, submit the annotation to the database
          this.submitAnnotationToDB(annotationObj)
            .then((response) => {
              console.log(response);
              // get the annotation id
              annotationObj.annotationID = response.data.insert_id;

              let newSavedContent = savedContent;
              newSavedContent.push(annotationObj);

              this.setState({
                //showSavedBadge: true,
                savedContent: newSavedContent,
              });
            })
            .catch((error) => {
              console.log(error);
            });
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      annotationObj.sessionID = cached_session_id;

      // ok, submit the annotation to the database
      this.submitAnnotationToDB(annotationObj)
        .then((response) => {
          console.log(response);
          // get the annotation id
          annotationObj.annotationID = response.data.insert_id;

          let newSavedContent = savedContent;
          newSavedContent.push(annotationObj);

          this.setState({
            //showSavedBadge: true,
            savedContent: newSavedContent,
          });
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }

  onUnHelpfulButtonDown(event) {
    console.log("onUnHelpfulButtonDown");
    const { savedContent } = this.state;

    // not sure if this will work
    const { language } = this.state;

    var cached_feedback_id = Variable.getValue("feedback_id");
    var cached_risk_id = Variable.getValue("risk_id");
    var cached_reflection_id = Variable.getValue("reflection_id");
    var cached_session_id = Variable.getValue("session_id");

    let annotationObj = this.extractAnnotation(event);
    if (annotationObj == null) {
      return;
    }
    annotationObj.category = "unhelpful";
    // and clear the selection
    this.clearSelection();

    this.setState({
      needPopover: false,
    });

    this.saveAnnotation(annotationObj);
    /*
    let message =
      "Thanks for letting us know that you did not find this helpful! Your feedback will help us make this site better.";
    this.addNotification(message, "success");
    */
  }

  onHelpfulButtonDown(event) {
    console.log("onHelpfulButtonDown");
    const { savedContent } = this.state;

    // not sure if this will work
    const { language } = this.state;

    var cached_feedback_id = Variable.getValue("feedback_id");
    var cached_risk_id = Variable.getValue("risk_id");
    var cached_reflection_id = Variable.getValue("reflection_id");
    var cached_session_id = Variable.getValue("session_id");

    let annotationObj = this.extractAnnotation(event);
    if (annotationObj == null) {
      return;
    }
    annotationObj.category = "helpful";
    // and clear the selection
    this.clearSelection();

    this.setState({
      needPopover: false,
    });

    this.saveAnnotation(annotationObj);
    /*
    let message =
      "Thanks for letting us know that you find this helpful! Your feedback will help us make this site better.";
    this.addNotification(message, "success");
    */
  }

  onSaveButtonDown(event) {
    console.log("onSaveButtonDown");
    const { savedContent } = this.state;

    // not sure if this will work
    const { language } = this.state;

    var cached_feedback_id = Variable.getValue("feedback_id");
    var cached_risk_id = Variable.getValue("risk_id");
    var cached_reflection_id = Variable.getValue("reflection_id");
    var cached_session_id = Variable.getValue("session_id");

    let annotationObj = this.extractAnnotation(event);
    if (annotationObj == null) {
      return;
    }
    annotationObj.category = "saved";
    // and clear the selection
    this.clearSelection();

    this.setState({
      showSavedBadge: true,
      needPopover: false,
    });

    this.saveAnnotation(annotationObj);
    /*
    let message = "Content saved. Click the counter to see and print it.";
    this.addNotification(message, "success");
    */
  }

  onMouseUp(event) {
    console.log("App.onMouseUp");

    // try and see

    //var selObj = window.getSelection();
    //console.log(JSON.stringify(selObj, null, 2));

    var text = "";

    if (window.getSelection) {
      console.log("selction: " + window.getSelection());
      text = window.getSelection().toString();
    } else if (document.selection && document.selection.type != "Control") {
      console.log("selction: " + document.selection.createRange());
      text = document.selection.createRange().text;
    }

    if (text.length > 0) {
      //event.preventDefault();
      console.log(`pageX: ${event.pageX}, pageY: ${event.pageY}`);

      this.setState({
        // set to false to disable  save feature
        //needPopover: true,
        popoverLeft: event.pageX,
        popoverTop: event.pageY,
      });
    } else {
      this.setState({
        needPopover: false,
      });
    }

    console.log("select: " + text);
  }

  componentDidMount() {
    console.log("App.componentDidMount");
    const { deviceType } = this.state;

    this._notificationSystem = this.refs.notificationSystem;

    // disable to not remind people about the saved feature
    // try this
    /*
    let message =
      "You can highlight and save the parts you want to keep for later to print out.";
    if (deviceType == "desktop") {
      this.addNotification(message, "success");
    }
    */

   let url = window.location.href;

   console.log(`window.location.href: ${url}`);

   let route = MyUtility.getRouteFromFullURL(url);
   console.log(`window.location.href route: ${route}`);

   let language = MyUtility.getLanguageFromURL(route);

   console.log(`window.location.href language: ${language}`);

    let deviceInfo = JSON.stringify(deviceDetect(), null, 2);

    let innerWidth = window.innerWidth;
    let innerHeight = window.innerHeight;

    console.log(`App.componentDidMount: deviceType: ${deviceType}, innerWidth: ${innerWidth}, innerHeight: ${innerHeight}, deviceInfo: ${deviceInfo}`);
   

   let message = "";

   // try this
   /*
   message =
     "You can highlight and save the parts you want to keep for later to print out.";
   if(deviceType == "desktop"){
     this.addNotification(message, "success");
   }
   */
  
  if(isIE){
   this.addNotification("", "success", "tc", 0, (Content.all_content[language].site.ie_message));
   }

   //var deviceInfo = JSON.stringify(deviceDetect(), null, 2);

   var cached_feedback_id = Variable.getValue("feedback_id");
   var cached_risk_id = Variable.getValue("risk_id");
   var cached_reflection_id = Variable.getValue("reflection_id");
   var cached_session_id = Variable.getValue("session_id");

   if (cached_session_id == "variable default") {
     // no session id yet
     ServerService.insertSession(
       deviceInfo,
       deviceType,
       innerWidth,
       innerHeight,
       cached_feedback_id,
       cached_reflection_id,
       cached_risk_id,
       language
     )
       .then((response) => {
         console.log(response);

         console.log(
           "Get Session: " + JSON.stringify(response.data.insert_id)
         );
         Variable.setValue("session_id", response.data.insert_id);
         console.log(
           "After insert, session_id = " + Variable.getValue("session_id")
         );
       })
       .catch((error) => {
         console.log(error);
       });
   }
  }

  render() {
    const { gAnalytics } = this.props;

    const {
      language,
      deviceType,
      showSavedBadge,
      savedContent,
      needPopover,
      popoverLeft,
      popoverTop,
      popoverTitle = "Tools",
    } = this.state;

    var savedComponentContent = "";
    let siteValue = undefined;

    if (
      language != undefined &&
      Content.all_content[language] != undefined &&
      Content.all_content[language]["savedComponent"] != undefined
    ) {
      savedComponentContent = Content.all_content[language]["savedComponent"];
      siteValue = Content.all_content[language]["site"];
    } else {
      savedComponentContent = Content.all_content["English"]["savedComponent"];
      siteValue = Content.all_content["English"]["site"];
    }

    return (
      //<Provider store={store}>
      //<ConnectedRouter history={history}>
      //<Router basename="ShouldIScreen">
      <Router>
        <div onMouseUp={this.onMouseUp} className="my-body">
          <Suspense fallback={<div>{siteValue.loading_message}...</div>}>
            <div className="my-content">
              <Switch>
                <Route
                  exact
                  path="/"
                  render={(props) => {
                    let defaultURL = this.getDefaultURL();

                    return (
                      <Redirect
                        to={{
                          pathname: defaultURL,
                        }}
                        props={props}
                      />
                    );
                  }}
                />
                <Route
                  exact
                  path="/English"
                  render={(props) => {
                    let defaultURL = this.getDefaultURL();

                    return (
                      <Redirect
                        to={{
                          pathname: "/English/home",
                        }}
                        props={props}
                      />
                    );
                  }}
                />
                <Route
                  exact
                  path="/Español"
                  render={(props) => {
                    let defaultURL = this.getDefaultURL();

                    return (
                      <Redirect
                        to={{
                          pathname: "/Español/inicio",
                        }}
                        props={props}
                      />
                    );
                  }}
                />
                <Route
                  exact
                  path="/Chinese"
                  render={(props) => {
                    let defaultURL = this.getDefaultURL();

                    return (
                      <Redirect
                        to={{
                          pathname: "/Chinese/home",
                        }}
                        props={props}
                      />
                    );
                  }}
                />
                <Route
                  exact
                  path="/TraditionalChinese"
                  render={(props) => {
                    let defaultURL = this.getDefaultURL();

                    return (
                      <Redirect
                        to={{
                          pathname: "/TraditionalChinese/home",
                        }}
                        props={props}
                      />
                    );
                  }}
                />
                <Route
                  exact
                  path="/SimplifiedChinese"
                  render={(props) => {
                    let defaultURL = this.getDefaultURL();

                    return (
                      <Redirect
                        to={{
                          pathname: "/SimplifiedChinese/home",
                        }}
                        props={props}
                      />
                    );
                  }}
                />
                <Route
                  exact
                  path="/Portuguese"
                  render={(props) => {
                    let defaultURL = this.getDefaultURL();

                    return (
                      <Redirect
                        to={{
                          pathname: "/Portuguese/inicio",
                        }}
                        props={props}
                      />
                    );
                  }}
                />
                <Route
                  exact
                  path="/English/home"
                  render={(props) => (
                    <HomePage
                      mainContentStyle={mainContentStyle}
                      changeLanguage={this.changeLanguage}
                      Content={Content}
                      deviceType={deviceType}
                      savedContent={savedContent}
                      {...this.props}
                      {...props}
                    />
                  )}
                />
                <Route
                  exact
                  path="/Español/inicio"
                  render={(props) => (
                    <HomePage
                      mainContentStyle={mainContentStyle}
                      changeLanguage={this.changeLanguage}
                      Content={Content}
                      deviceType={deviceType}
                      savedContent={savedContent}
                      {...this.props}
                      {...props}
                    />
                  )}
                />
                <Route
                  exact
                  path="/Chinese/home"
                  render={(props) => {
                    let defaultURL = this.getDefaultURL();

                    return (
                      <Redirect
                        to={{
                          pathname: "/TraditionalChinese/home",
                        }}
                        props={props}
                      />
                    );
                  }}
                />
                <Route
                  exact
                  path="/TraditionalChinese/home"
                  render={(props) => (
                    <HomePage
                      mainContentStyle={mainContentStyle}
                      changeLanguage={this.changeLanguage}
                      Content={Content}
                      deviceType={deviceType}
                      savedContent={savedContent}
                      {...this.props}
                      {...props}
                    />
                  )}
                />
                <Route
                  exact
                  path="/SimplifiedChinese/home"
                  render={(props) => (
                    <HomePage
                      mainContentStyle={mainContentStyle}
                      changeLanguage={this.changeLanguage}
                      Content={Content}
                      deviceType={deviceType}
                      savedContent={savedContent}
                      {...this.props}
                      {...props}
                    />
                  )}
                />
                <Route
                  exact
                  path="/Portuguese/inicio"
                  render={(props) => (
                    <HomePage
                      mainContentStyle={mainContentStyle}
                      changeLanguage={this.changeLanguage}
                      Content={Content}
                      deviceType={deviceType}
                      savedContent={savedContent}
                      {...this.props}
                      {...props}
                    />
                  )}
                />
                <Route
                  exact
                  path="/home"
                  render={(props) => {
                    let defaultURL = this.getDefaultURL();

                    return (
                      <Redirect
                        to={{
                          pathname: "/English/home",
                        }}
                        props={props}
                      />
                    );
                  }}
                />
                <Route
                  exact
                  path="/English/how-is-screening-done"
                  render={(props) => (
                    <HowPage
                      mainContentStyle={mainContentStyle}
                      changeLanguage={this.changeLanguage}
                      Content={Content}
                      deviceType={deviceType}
                      savedContent={savedContent}
                      {...this.props}
                      {...props}
                    />
                  )}
                />
                <Route
                  exact
                  path="/Chinese/how-is-screening-done"
                  render={(props) => {
                    let defaultURL = this.getDefaultURL();

                    return (
                      <Redirect
                        to={{
                          pathname: "/TraditionalChinese/how-is-screening-done",
                        }}
                        props={props}
                      />
                    );
                  }}
                />
                <Route
                  exact
                  path="/SimplifiedChinese/how-is-screening-done"
                  render={(props) => (
                    <HowPage
                      mainContentStyle={mainContentStyle}
                      changeLanguage={this.changeLanguage}
                      Content={Content}
                      deviceType={deviceType}
                      savedContent={savedContent}
                      {...this.props}
                      {...props}
                    />
                  )}
                />
                <Route
                  exact
                  path="/TraditionalChinese/how-is-screening-done"
                  render={(props) => (
                    <HowPage
                      mainContentStyle={mainContentStyle}
                      changeLanguage={this.changeLanguage}
                      Content={Content}
                      deviceType={deviceType}
                      savedContent={savedContent}
                      {...this.props}
                      {...props}
                    />
                  )}
                />
                <Route
                  exact
                  path="/Español/que-es-la-deteccion-del-cancer-de-pulmon"
                  render={(props) => (
                    <HowPage
                      mainContentStyle={mainContentStyle}
                      changeLanguage={this.changeLanguage}
                      Content={Content}
                      deviceType={deviceType}
                      savedContent={savedContent}
                      {...this.props}
                      {...props}
                    />
                  )}
                />
                <Route
                  exact
                  path="/Portuguese/como-e-realizado-o-rastreamento"
                  render={(props) => (
                    <HowPage
                      mainContentStyle={mainContentStyle}
                      changeLanguage={this.changeLanguage}
                      Content={Content}
                      deviceType={deviceType}
                      savedContent={savedContent}
                      {...this.props}
                      {...props}
                    />
                  )}
                />
                <Route
                  exact
                  path="/how-is-screening-done"
                  render={(props) => {
                    let defaultURL = this.getDefaultURL();

                    return (
                      <Redirect
                        to={{
                          pathname: "/English/how-is-screening-done",
                        }}
                        props={props}
                      />
                    );
                  }}
                />
                <Route
                  exact
                  path="/English/benefits-and-harms-screening"
                  render={(props) => (
                    <BenefitHarmPage
                      mainContentStyle={mainContentStyle}
                      changeLanguage={this.changeLanguage}
                      Content={Content}
                      deviceType={deviceType}
                      savedContent={savedContent}
                      {...this.props}
                      {...props}
                    />
                  )}
                />
                <Route
                  exact
                  path="/Chinese/benefits-and-harms-screening"
                  render={(props) => {
                    let defaultURL = this.getDefaultURL();

                    return (
                      <Redirect
                        to={{
                          pathname: "/TraditionalChinese/benefits-and-harms-screening",
                        }}
                        props={props}
                      />
                    );
                  }}
                />
                <Route
                  exact
                  path="/SimplifiedChinese/benefits-and-harms-screening"
                  render={(props) => (
                    <BenefitHarmPage
                      mainContentStyle={mainContentStyle}
                      changeLanguage={this.changeLanguage}
                      Content={Content}
                      deviceType={deviceType}
                      savedContent={savedContent}
                      {...this.props}
                      {...props}
                    />
                  )}
                />
                <Route
                  exact
                  path="/TraditionalChinese/benefits-and-harms-screening"
                  render={(props) => (
                    <BenefitHarmPage
                      mainContentStyle={mainContentStyle}
                      changeLanguage={this.changeLanguage}
                      Content={Content}
                      deviceType={deviceType}
                      savedContent={savedContent}
                      {...this.props}
                      {...props}
                    />
                  )}
                />
                <Route
                  exact
                  path="/Español/beneficios-y-efectos-adversos-de-la-prueba"
                  render={(props) => (
                    <BenefitHarmPage
                      mainContentStyle={mainContentStyle}
                      changeLanguage={this.changeLanguage}
                      Content={Content}
                      deviceType={deviceType}
                      savedContent={savedContent}
                      {...this.props}
                      {...props}
                    />
                  )}
                />
                <Route
                  exact
                  path="/Portuguese/beneficios-e-riscos-do-rastreamento"
                  render={(props) => (
                    <BenefitHarmPage
                      mainContentStyle={mainContentStyle}
                      changeLanguage={this.changeLanguage}
                      Content={Content}
                      deviceType={deviceType}
                      savedContent={savedContent}
                      {...this.props}
                      {...props}
                    />
                  )}
                />
                <Route
                  exact
                  path="/benefits-and-harms-screening"
                  render={(props) => {
                    let defaultURL = this.getDefaultURL();

                    return (
                      <Redirect
                        to={{
                          pathname: "/English/benefits-and-harms-screening",
                        }}
                        props={props}
                      />
                    );
                  }}
                />
                <Route
                  exact
                  path="/compare-with-other-screening-tests"
                  render={(props) => {
                    let defaultURL = this.getDefaultURL();
                    return (
                      <Redirect
                        to={{
                          pathname: "/English/how-is-screening-done",
                        }}
                        props={props}
                      />
                    );
                  }}
                />
                <Route
                  exact
                  path="/English/compare-with-other-screening-tests"
                  render={(props) => {
                    let defaultURL = this.getDefaultURL();
                    return (
                      <Redirect
                        to={{
                          pathname: "/English/how-is-screening-done",
                        }}
                        props={props}
                      />
                    );
                  }}
                />
                <Route
                  exact
                  path="/TraditionalChinese/compare-with-other-screening-tests"
                  render={(props) => {
                    let defaultURL = this.getDefaultURL();
                    return (
                      <Redirect
                        to={{
                          pathname: "/TraditionalChinese/how-is-screening-done",
                        }}
                        props={props}
                      />
                    );
                  }}
                />
                <Route
                  exact
                  path="/SimplifiedChinese/compare-with-other-screening-tests"
                  render={(props) => {
                    let defaultURL = this.getDefaultURL();
                    return (
                      <Redirect
                        to={{
                          pathname: "/SimplifiedChinese/how-is-screening-done",
                        }}
                        props={props}
                      />
                    );
                  }}
                />
                <Route
                  exact
                  path="/Chinese/compare-with-other-screening-tests"
                  render={(props) => {
                    let defaultURL = this.getDefaultURL();
                    return (
                      <Redirect
                        to={{
                          pathname: "/TraditionalChinese/compare-with-other-screening-tests",
                        }}
                        props={props}
                      />
                    );
                  }}
                />
                <Route
                  exact
                  path="/Español/comparado-con-otras-pruebas-de-deteccion-temprana"
                  render={(props) => {
                    let defaultURL = this.getDefaultURL();
                    return (
                      <Redirect
                        to={{
                          pathname:
                            "/Español/que-es-la-deteccion-del-cancer-de-pulmon",
                        }}
                        props={props}
                      />
                    );
                  }}
                />
                <Route
                  exact
                  path="/English/causes-of-lung-cancer"
                  render={(props) => (
                    <CausePage
                      mainContentStyle={mainContentStyle}
                      changeLanguage={this.changeLanguage}
                      Content={Content}
                      deviceType={deviceType}
                      savedContent={savedContent}
                      {...this.props}
                      {...props}
                    />
                  )}
                />
                <Route
                  exact
                  path="/TraditionalChinese/causes-of-lung-cancer"
                  render={(props) => (
                    <CausePage
                      mainContentStyle={mainContentStyle}
                      changeLanguage={this.changeLanguage}
                      Content={Content}
                      deviceType={deviceType}
                      savedContent={savedContent}
                      {...this.props}
                      {...props}
                    />
                  )}
                />
                <Route
                  exact
                  path="/Español/causas-del-cancer-de-pulmon"
                  render={(props) => (
                    <CausePage
                      mainContentStyle={mainContentStyle}
                      changeLanguage={this.changeLanguage}
                      Content={Content}
                      deviceType={deviceType}
                      savedContent={savedContent}
                      {...this.props}
                      {...props}
                    />
                  )}
                />
                <Route
                  exact
                  path="/causes-of-lung-cancer"
                  render={(props) => {
                    let defaultURL = this.getDefaultURL();

                    return (
                      <Redirect
                        to={{
                          pathname: "/English/causes-of-lung-cancer",
                        }}
                        props={props}
                      />
                    );
                  }}
                />
                                <Route
                  exact
                  path="/Chinese/causes-of-lung-cancer"
                  render={(props) => {
                    let defaultURL = this.getDefaultURL();

                    return (
                      <Redirect
                        to={{
                          pathname: "/TraditionalChinese/causes-of-lung-cancer",
                        }}
                        props={props}
                      />
                    );
                  }}
                />
                <Route
                  exact
                  path="/SimplifiedChinese/causes-of-lung-cancer"
                  render={(props) => (
                    <CausePage
                      mainContentStyle={mainContentStyle}
                      changeLanguage={this.changeLanguage}
                      Content={Content}
                      deviceType={deviceType}
                      savedContent={savedContent}
                      {...this.props}
                      {...props}
                    />
                  )}
                />
                                <Route
                  exact
                  path="/Portuguese/causas-do-cancer-de-pulmao"
                  render={(props) => (
                    <CausePage
                      mainContentStyle={mainContentStyle}
                      changeLanguage={this.changeLanguage}
                      Content={Content}
                      deviceType={deviceType}
                      savedContent={savedContent}
                      {...this.props}
                      {...props}
                    />
                  )}
                />
                <Route
                  exact
                  path="/English/pack-year-calculator"
                  render={(props) => (
                    <PackPage
                      mainContentStyle={mainContentStyle}
                      changeLanguage={this.changeLanguage}
                      Content={Content}
                      deviceType={deviceType}
                      savedContent={savedContent}
                      {...this.props}
                      {...props}
                    />
                  )}
                />
                <Route
                  exact
                  path="/TraditionalChinese/pack-year-calculator"
                  render={(props) => (
                    <PackPage
                      mainContentStyle={mainContentStyle}
                      changeLanguage={this.changeLanguage}
                      Content={Content}
                      deviceType={deviceType}
                      savedContent={savedContent}
                      {...this.props}
                      {...props}
                    />
                  )}
                />
                <Route
                  exact
                  path="/Portuguese/calculadora-de-anos-maco"
                  render={(props) => (
                    <PackPage
                      mainContentStyle={mainContentStyle}
                      changeLanguage={this.changeLanguage}
                      Content={Content}
                      deviceType={deviceType}
                      savedContent={savedContent}
                      {...this.props}
                      {...props}
                    />
                  )}
                />
                                <Route
                  exact
                  path="/Chinese/pack-year-calculator"
                  render={(props) => {
                    let defaultURL = this.getDefaultURL();

                    return (
                      <Redirect
                        to={{
                          pathname: "/TraditionalChinese/pack-year-calculator",
                        }}
                        props={props}
                      />
                    );
                  }}
                />
                <Route
                  exact
                  path="/SimplifiedChinese/pack-year-calculator"
                  render={(props) => (
                    <PackPage
                      mainContentStyle={mainContentStyle}
                      changeLanguage={this.changeLanguage}
                      Content={Content}
                      deviceType={deviceType}
                      savedContent={savedContent}
                      {...this.props}
                      {...props}
                    />
                  )}
                />
                <Route
                  exact
                  path="/Español/calculadora-de-paquete-anos"
                  render={(props) => (
                    <PackPage
                      mainContentStyle={mainContentStyle}
                      changeLanguage={this.changeLanguage}
                      Content={Content}
                      deviceType={deviceType}
                      savedContent={savedContent}
                      {...this.props}
                      {...props}
                    />
                  )}
                />
                <Route
                  exact
                  path="/pack-year-calculator"
                  render={(props) => {
                    let defaultURL = this.getDefaultURL();

                    return (
                      <Redirect
                        to={{
                          pathname: "/English/pack-year-calculator",
                        }}
                        props={props}
                      />
                    );
                  }}
                />
                <Route
                  exact
                  path="/English/risk-reduction-lung-cancer"
                  render={(props) => (
                    <RiskPage
                      mainContentStyle={mainContentStyle}
                      changeLanguage={this.changeLanguage}
                      Content={Content}
                      deviceType={deviceType}
                      savedContent={savedContent}
                      {...this.props}
                      {...props}
                    />
                  )}
                />
                <Route
                  exact
                  path="/TraditionalChinese/risk-reduction-lung-cancer"
                  render={(props) => (
                    <RiskPage
                      mainContentStyle={mainContentStyle}
                      changeLanguage={this.changeLanguage}
                      Content={Content}
                      deviceType={deviceType}
                      savedContent={savedContent}
                      {...this.props}
                      {...props}
                    />
                  )}
                />
                <Route
                  exact
                  path="/Chinese/risk-reduction-lung-cancer"
                  render={(props) => {
                    let defaultURL = this.getDefaultURL();

                    return (
                      <Redirect
                        to={{
                          pathname: "/TraditionalChinese/risk-reduction-lung-cancer",
                        }}
                        props={props}
                      />
                    );
                  }}
                />
                <Route
                  exact
                  path="/SimplifiedChinese/risk-reduction-lung-cancer"
                  render={(props) => (
                    <RiskPage
                      mainContentStyle={mainContentStyle}
                      changeLanguage={this.changeLanguage}
                      Content={Content}
                      deviceType={deviceType}
                      savedContent={savedContent}
                      {...this.props}
                      {...props}
                    />
                  )}
                />
                                <Route
                  exact
                  path="/Portuguese/reducao-de-risco-de-cancer-de-pulmao"
                  render={(props) => (
                    <RiskPage
                      mainContentStyle={mainContentStyle}
                      changeLanguage={this.changeLanguage}
                      Content={Content}
                      deviceType={deviceType}
                      savedContent={savedContent}
                      {...this.props}
                      {...props}
                    />
                  )}
                />
                <Route
                  exact
                  path="/Español/reducir-riesgo-de-cancer-de-pulmon"
                  render={(props) => (
                    <RiskPage
                      mainContentStyle={mainContentStyle}
                      changeLanguage={this.changeLanguage}
                      Content={Content}
                      deviceType={deviceType}
                      savedContent={savedContent}
                      {...this.props}
                      {...props}
                    />
                  )}
                />
                <Route
                  exact
                  path="/risk-reduction-lung-cancer"
                  render={(props) => {
                    let defaultURL = this.getDefaultURL();

                    return (
                      <Redirect
                        to={{
                          pathname: "/English/risk-reduction-lung-cancer",
                        }}
                        props={props}
                      />
                    );
                  }}
                />
                <Route
                  exact
                  path="/English/lung-cancer-risk-calculator*"
                  render={(props) => (
                    <CalculatePage
                      mainContentStyle={mainContentStyle}
                      changeLanguage={this.changeLanguage}
                      Content={Content}
                      deviceType={deviceType}
                      savedContent={savedContent}
                      {...this.props}
                      {...props}
                    />
                  )}
                />
                <Route
                  exact
                  path="/TraditionalChinese/lung-cancer-risk-calculator*"
                  render={(props) => (
                    <CalculatePage
                      mainContentStyle={mainContentStyle}
                      changeLanguage={this.changeLanguage}
                      Content={Content}
                      deviceType={deviceType}
                      savedContent={savedContent}
                      {...this.props}
                      {...props}
                    />
                  )}
                />
                <Route
                  exact
                  path="/Chinese/lung-cancer-risk-calculator*"
                  render={(props) => {
                    let defaultURL = this.getDefaultURL();

                    return (
                      <Redirect
                        to={{
                          pathname: "/TraditionalChinese/lung-cancer-risk-calculator*",
                        }}
                        props={props}
                      />
                    );
                  }}
                />
                <Route
                  exact
                  path="/SimplifiedChinese/lung-cancer-risk-calculator*"
                  render={(props) => (
                    <CalculatePage
                      mainContentStyle={mainContentStyle}
                      changeLanguage={this.changeLanguage}
                      Content={Content}
                      deviceType={deviceType}
                      savedContent={savedContent}
                      {...this.props}
                      {...props}
                    />
                  )}
                />
                                <Route
                  exact
                  path="/Portuguese/calculadora-de-risco-de-cancer-de-pulmao*"
                  render={(props) => (
                    <CalculatePage
                      mainContentStyle={mainContentStyle}
                      changeLanguage={this.changeLanguage}
                      Content={Content}
                      deviceType={deviceType}
                      savedContent={savedContent}
                      {...this.props}
                      {...props}
                    />
                  )}
                />
                <Route
                  exact
                  path="/Español/calculadora-del-riesgo-de-cancer-de-pulmon"
                  render={(props) => (
                    <CalculatePage
                      mainContentStyle={mainContentStyle}
                      changeLanguage={this.changeLanguage}
                      Content={Content}
                      deviceType={deviceType}
                      savedContent={savedContent}
                      {...this.props}
                      {...props}
                    />
                  )}
                />
                <Route
                  exact
                  path="/lung-cancer-risk-calculator"
                  render={(props) => {
                    let defaultURL = this.getDefaultURL();

                    return (
                      <Redirect
                        to={{
                          pathname: "/English/lung-cancer-risk-calculator",
                        }}
                        props={props}
                      />
                    );
                  }}
                />
                <Route
                  exact
                  path="/English/result/:display?"
                  render={(props) => (
                    <ResultPage
                      mainContentStyle={mainContentStyle}
                      changeLanguage={this.changeLanguage}
                      Content={Content}
                      deviceType={deviceType}
                      savedContent={savedContent}
                      {...this.props}
                      {...props}
                    />
                  )}
                />
                <Route
                  exact
                  path="/TraditionalChinese/result/:display?"
                  render={(props) => (
                    <ResultPage
                      mainContentStyle={mainContentStyle}
                      changeLanguage={this.changeLanguage}
                      Content={Content}
                      deviceType={deviceType}
                      savedContent={savedContent}
                      {...this.props}
                      {...props}
                    />
                  )}
                />
                <Route
                  exact
                  path="/Chinese/result/:display?"
                  render={(props) => {
                    let defaultURL = this.getDefaultURL();

                    return (
                      <Redirect
                        to={{
                          pathname: "/TraditionalChinese/result/:display?",
                        }}
                        props={props}
                      />
                    );
                  }}
                />
                <Route
                  exact
                  path="/SimplifiedChinese/result/:display?"
                  render={(props) => (
                    <ResultPage
                      mainContentStyle={mainContentStyle}
                      changeLanguage={this.changeLanguage}
                      Content={Content}
                      deviceType={deviceType}
                      savedContent={savedContent}
                      {...this.props}
                      {...props}
                    />
                  )}
                />
                <Route
                  exact
                  path="/Portuguese/resultado/:display?"
                  render={(props) => (
                    <ResultPage
                      mainContentStyle={mainContentStyle}
                      changeLanguage={this.changeLanguage}
                      Content={Content}
                      deviceType={deviceType}
                      savedContent={savedContent}
                      {...this.props}
                      {...props}
                    />
                  )}
                />
                <Route
                  exact
                  path="/Español/resultado/:display?"
                  render={(props) => (
                    <ResultPage
                      mainContentStyle={mainContentStyle}
                      changeLanguage={this.changeLanguage}
                      Content={Content}
                      deviceType={deviceType}
                      savedContent={savedContent}
                      {...this.props}
                      {...props}
                    />
                  )}
                />
                <Route
                  exact
                  path="/English/coverage"
                  render={(props) => (
                    <CoveragePage
                      mainContentStyle={mainContentStyle}
                      changeLanguage={this.changeLanguage}
                      Content={Content}
                      deviceType={deviceType}
                      savedContent={savedContent}
                      {...this.props}
                      {...props}
                    />
                  )}
                />
                <Route
                  exact
                  path="/TraditionalChinese/coverage"
                  render={(props) => (
                    <CoveragePage
                      mainContentStyle={mainContentStyle}
                      changeLanguage={this.changeLanguage}
                      Content={Content}
                      deviceType={deviceType}
                      savedContent={savedContent}
                      {...this.props}
                      {...props}
                    />
                  )}
                />
                <Route
                  exact
                  path="/Chinese/coverage"
                  render={(props) => {
                    let defaultURL = this.getDefaultURL();

                    return (
                      <Redirect
                        to={{
                          pathname: "/TraditionalChinese/coverage",
                        }}
                        props={props}
                      />
                    );
                  }}
                />
                <Route
                  exact
                  path="/SimplifiedChinese/coverage"
                  render={(props) => (
                    <CoveragePage
                      mainContentStyle={mainContentStyle}
                      changeLanguage={this.changeLanguage}
                      Content={Content}
                      deviceType={deviceType}
                      savedContent={savedContent}
                      {...this.props}
                      {...props}
                    />
                  )}
                />
                <Route
                  exact
                  path="/Portuguese/cobertura"
                  render={(props) => (
                    <CoveragePage
                      mainContentStyle={mainContentStyle}
                      changeLanguage={this.changeLanguage}
                      Content={Content}
                      deviceType={deviceType}
                      savedContent={savedContent}
                      {...this.props}
                      {...props}
                    />
                  )}
                />
                <Route
                  exact
                  path="/Español/cobertura"
                  render={(props) => (
                    <CoveragePage
                      mainContentStyle={mainContentStyle}
                      changeLanguage={this.changeLanguage}
                      Content={Content}
                      deviceType={deviceType}
                      savedContent={savedContent}
                      {...this.props}
                      {...props}
                    />
                  )}
                />
                <Route
                  exact
                  path="/English/saved"
                  render={(props) => (
                    <SavedPage
                      mainContentStyle={mainContentStyle}
                      changeLanguage={this.changeLanguage}
                      savedContent={this.state.savedContent}
                      Content={Content}
                      deviceType={deviceType}
                      savedContent={savedContent}
                      {...this.props}
                      {...props}
                    />
                  )}
                />
                <Route
                  exact
                  path="/TraditionalChinese/saved"
                  render={(props) => (
                    <SavedPage
                      mainContentStyle={mainContentStyle}
                      changeLanguage={this.changeLanguage}
                      savedContent={this.state.savedContent}
                      Content={Content}
                      deviceType={deviceType}
                      savedContent={savedContent}
                      {...this.props}
                      {...props}
                    />
                  )}
                />
                                <Route
                  exact
                  path="/Chinese/saved"
                  render={(props) => {
                    let defaultURL = this.getDefaultURL();

                    return (
                      <Redirect
                        to={{
                          pathname: "/TraditionalChinese/saved",
                        }}
                        props={props}
                      />
                    );
                  }}
                />
                <Route
                  exact
                  path="/SimplifiedChinese/saved"
                  render={(props) => (
                    <SavedPage
                      mainContentStyle={mainContentStyle}
                      changeLanguage={this.changeLanguage}
                      Content={Content}
                      deviceType={deviceType}
                      savedContent={savedContent}
                      {...this.props}
                      {...props}
                    />
                  )}
                />
                <Route
                  exact
                  path="/Español/guardado"
                  render={(props) => (
                    <SavedPage
                      mainContentStyle={mainContentStyle}
                      changeLanguage={this.changeLanguage}
                      savedContent={this.state.savedContent}
                      Content={Content}
                      deviceType={deviceType}
                      savedContent={savedContent}
                      {...this.props}
                      {...props}
                    />
                  )}
                />
                <Route
                  exact
                  path="/English/publications"
                  render={(props) => (
                    <PublicationPage
                      mainContentStyle={mainContentStyle}
                      changeLanguage={this.changeLanguage}
                      Content={Content}
                      deviceType={deviceType}
                      savedContent={savedContent}
                      {...this.props}
                      {...props}
                    />
                  )}
                />
                <Route
                  exact
                  path="/Chinese/publications"
                  render={(props) => {
                    let defaultURL = this.getDefaultURL();

                    return (
                      <Redirect
                        to={{
                          pathname: "/TraditionalChinese/publications",
                        }}
                        props={props}
                      />
                    );
                  }}
                />
                <Route
                  exact
                  path="/SimplifiedChinese/publications"
                  render={(props) => (
                    <PublicationPage
                      mainContentStyle={mainContentStyle}
                      changeLanguage={this.changeLanguage}
                      Content={Content}
                      deviceType={deviceType}
                      savedContent={savedContent}
                      {...this.props}
                      {...props}
                    />
                  )}
                />
                <Route
                  exact
                  path="/TraditionalChinese/publications"
                  render={(props) => (
                    <PublicationPage
                      mainContentStyle={mainContentStyle}
                      changeLanguage={this.changeLanguage}
                      Content={Content}
                      deviceType={deviceType}
                      savedContent={savedContent}
                      {...this.props}
                      {...props}
                    />
                  )}
                />
                <Route
                  exact
                  path="/Portuguese/publicacoes"
                  render={(props) => (
                    <PublicationPage
                      mainContentStyle={mainContentStyle}
                      changeLanguage={this.changeLanguage}
                      Content={Content}
                      deviceType={deviceType}
                      savedContent={savedContent}
                      {...this.props}
                      {...props}
                    />
                  )}
                />
                <Route
                  exact
                  path="/Español/publicaciones"
                  render={(props) => (
                    <PublicationPage
                      mainContentStyle={mainContentStyle}
                      changeLanguage={this.changeLanguage}
                      savedContent={this.state.savedContent}
                      Content={Content}
                      deviceType={deviceType}
                      savedContent={savedContent}
                      {...this.props}
                      {...props}
                    />
                  )}
                />
                <Route
                  exact
                  path="/English/disclaimer"
                  render={(props) => (
                    <DisclaimerPage
                      mainContentStyle={mainContentStyle}
                      changeLanguage={this.changeLanguage}
                      Content={Content}
                      deviceType={deviceType}
                      savedContent={savedContent}
                      {...this.props}
                      {...props}
                    />
                  )}
                />
                <Route
                  exact
                  path="/TraditionalChinese/disclaimer"
                  render={(props) => (
                    <DisclaimerPage
                      mainContentStyle={mainContentStyle}
                      changeLanguage={this.changeLanguage}
                      Content={Content}
                      deviceType={deviceType}
                      savedContent={savedContent}
                      {...this.props}
                      {...props}
                    />
                  )}
                />
                <Route
                  exact
                  path="/Chinese/disclaimer"
                  render={(props) => {
                    let defaultURL = this.getDefaultURL();

                    return (
                      <Redirect
                        to={{
                          pathname: "/TraditionalChinese/disclaimer",
                        }}
                        props={props}
                      />
                    );
                  }}
                />
                <Route
                  exact
                  path="/SimplifiedChinese/disclaimer"
                  render={(props) => (
                    <DisclaimerPage
                      mainContentStyle={mainContentStyle}
                      changeLanguage={this.changeLanguage}
                      Content={Content}
                      deviceType={deviceType}
                      savedContent={savedContent}
                      {...this.props}
                      {...props}
                    />
                  )}
                />
                <Route
                  exact
                  path="/Portuguese/aviso-legal"
                  render={(props) => (
                    <DisclaimerPage
                      mainContentStyle={mainContentStyle}
                      changeLanguage={this.changeLanguage}
                      Content={Content}
                      deviceType={deviceType}
                      savedContent={savedContent}
                      {...this.props}
                      {...props}
                    />
                  )}
                />
                <Route
                  exact
                  path="/Español/exención-de-responsabilidades"
                  render={(props) => (
                    <DisclaimerPage
                      mainContentStyle={mainContentStyle}
                      changeLanguage={this.changeLanguage}
                      Content={Content}
                      deviceType={deviceType}
                      savedContent={savedContent}
                      {...this.props}
                      {...props}
                    />
                  )}
                />
                <Route
                  exact
                  path="/disclaimer"
                  render={(props) => {
                    let defaultURL = this.getDefaultURL();

                    return (
                      <Redirect
                        to={{
                          pathname: "/English/disclaimer",
                        }}
                        props={props}
                      />
                    );
                  }}
                />
                <Route
                  exact
                  path="/English/development-team"
                  render={(props) => (
                    <TeamPage
                      mainContentStyle={mainContentStyle}
                      changeLanguage={this.changeLanguage}
                      Content={Content}
                      deviceType={deviceType}
                      savedContent={savedContent}
                      {...this.props}
                      {...props}
                    />
                  )}
                />
                <Route
                  exact
                  path="/TraditionalChinese/development-team"
                  render={(props) => (
                    <TeamPage
                      mainContentStyle={mainContentStyle}
                      changeLanguage={this.changeLanguage}
                      Content={Content}
                      deviceType={deviceType}
                      savedContent={savedContent}
                      {...this.props}
                      {...props}
                    />
                  )}
                />
                <Route
                  exact
                  path="/Chinese/development-team"
                  render={(props) => {
                    let defaultURL = this.getDefaultURL();

                    return (
                      <Redirect
                        to={{
                          pathname: "/TraditionalChinese/development-team",
                        }}
                        props={props}
                      />
                    );
                  }}
                />
                <Route
                  exact
                  path="/SimplifiedChinese/development-team"
                  render={(props) => (
                    <TeamPage
                      mainContentStyle={mainContentStyle}
                      changeLanguage={this.changeLanguage}
                      Content={Content}
                      deviceType={deviceType}
                      savedContent={savedContent}
                      {...this.props}
                      {...props}
                    />
                  )}
                />
                                <Route
                  exact
                  path="/Portuguese/equipe-de-desenvolvimento"
                  render={(props) => (
                    <TeamPage
                      mainContentStyle={mainContentStyle}
                      changeLanguage={this.changeLanguage}
                      Content={Content}
                      deviceType={deviceType}
                      savedContent={savedContent}
                      {...this.props}
                      {...props}
                    />
                  )}
                />
                <Route
                  exact
                  path="/Español/equipo-investigación-desarrollo"
                  render={(props) => (
                    <TeamPage
                      mainContentStyle={mainContentStyle}
                      changeLanguage={this.changeLanguage}
                      Content={Content}
                      deviceType={deviceType}
                      savedContent={savedContent}
                      {...this.props}
                      {...props}
                    />
                  )}
                />
                <Route
                  exact
                  path="/development-team"
                  render={(props) => {
                    let defaultURL = this.getDefaultURL();

                    return (
                      <Redirect
                        to={{
                          pathname: "/English/development-team",
                        }}
                        props={props}
                      />
                    );
                  }}
                />
                <Route
                  exact
                  path="/English/development-history"
                  render={(props) => (
                    <HistoryPage
                      mainContentStyle={mainContentStyle}
                      changeLanguage={this.changeLanguage}
                      Content={Content}
                      deviceType={deviceType}
                      savedContent={savedContent}
                      {...this.props}
                      {...props}
                    />
                  )}
                />
                <Route
                  exact
                  path="/TraditionalChinese/development-history"
                  render={(props) => (
                    <HistoryPage
                      mainContentStyle={mainContentStyle}
                      changeLanguage={this.changeLanguage}
                      Content={Content}
                      deviceType={deviceType}
                      savedContent={savedContent}
                      {...this.props}
                      {...props}
                    />
                  )}
                />
                                <Route
                  exact
                  path="/Chinese/development-history"
                  render={(props) => {
                    let defaultURL = this.getDefaultURL();

                    return (
                      <Redirect
                        to={{
                          pathname: "/TraditionalChinese/development-history",
                        }}
                        props={props}
                      />
                    );
                  }}
                />
                <Route
                  exact
                  path="/SimplifiedChinese/development-history"
                  render={(props) => (
                    <HistoryPage
                      mainContentStyle={mainContentStyle}
                      changeLanguage={this.changeLanguage}
                      Content={Content}
                      deviceType={deviceType}
                      savedContent={savedContent}
                      {...this.props}
                      {...props}
                    />
                  )}
                />
                <Route
                  exact
                  path="/Portuguese/historico-de-desenvolvimento"
                  render={(props) => (
                    <HistoryPage
                      mainContentStyle={mainContentStyle}
                      changeLanguage={this.changeLanguage}
                      Content={Content}
                      deviceType={deviceType}
                      savedContent={savedContent}
                      {...this.props}
                      {...props}
                    />
                  )}
                />
                <Route
                  exact
                  path="/Español/historia-de-desarrollo"
                  render={(props) => (
                    <HistoryPage
                      mainContentStyle={mainContentStyle}
                      changeLanguage={this.changeLanguage}
                      Content={Content}
                      deviceType={deviceType}
                      savedContent={savedContent}
                      {...this.props}
                      {...props}
                    />
                  )}
                />
                <Route
                  exact
                  path="/development-history"
                  render={(props) => {
                    let defaultURL = this.getDefaultURL();

                    return (
                      <Redirect
                        to={{
                          pathname: "/English/development-history",
                        }}
                        props={props}
                      />
                    );
                  }}
                />
                <Route
                  exact
                  path="/English/feedback"
                  render={(props) => (
                    <FeedbackPage
                      mainContentStyle={mainContentStyle}
                      changeLanguage={this.changeLanguage}
                      Content={Content}
                      deviceType={deviceType}
                      savedContent={savedContent}
                      {...this.props}
                      {...props}
                    />
                  )}
                />
                <Route
                  exact
                  path="/TraditionalChinese/feedback"
                  render={(props) => (
                    <FeedbackPage
                      mainContentStyle={mainContentStyle}
                      changeLanguage={this.changeLanguage}
                      Content={Content}
                      deviceType={deviceType}
                      savedContent={savedContent}
                      {...this.props}
                      {...props}
                    />
                  )}
                />
                                <Route
                  exact
                  path="/Chinese/feedback"
                  render={(props) => {
                    let defaultURL = this.getDefaultURL();

                    return (
                      <Redirect
                        to={{
                          pathname: "/TraditionalChinese/feedback",
                        }}
                        props={props}
                      />
                    );
                  }}
                />
                <Route
                  exact
                  path="/SimplifiedChinese/feedback"
                  render={(props) => (
                    <FeedbackPage
                      mainContentStyle={mainContentStyle}
                      changeLanguage={this.changeLanguage}
                      Content={Content}
                      deviceType={deviceType}
                      savedContent={savedContent}
                      {...this.props}
                      {...props}
                    />
                  )}
                />
                <Route
                  exact
                  path="/Portuguese/feedback"
                  render={(props) => (
                    <FeedbackPage
                      mainContentStyle={mainContentStyle}
                      changeLanguage={this.changeLanguage}
                      Content={Content}
                      deviceType={deviceType}
                      savedContent={savedContent}
                      {...this.props}
                      {...props}
                    />
                  )}
                />
                <Route
                  exact
                  path="/Español/comentarios"
                  render={(props) => (
                    <FeedbackPage
                      mainContentStyle={mainContentStyle}
                      changeLanguage={this.changeLanguage}
                      Content={Content}
                      deviceType={deviceType}
                      savedContent={savedContent}
                      {...this.props}
                      {...props}
                    />
                  )}
                />
                <Route
                  exact
                  path="/feedback"
                  render={(props) => {
                    let defaultURL = this.getDefaultURL();

                    return (
                      <Redirect
                        to={{
                          pathname: "/English/feedback",
                        }}
                        props={props}
                      />
                    );
                  }}
                />
                <Route
                  exact
                  path="/notfound"
                  render={(props) => (
                    <NoPage
                      mainContentStyle={mainContentStyle}
                      changeLanguage={this.changeLanguage}
                      Content={Content}
                      deviceType={deviceType}
                      savedContent={savedContent}
                      {...this.props}
                      {...props}
                    />
                  )}
                />
                <Route
                  render={(props) => {
                    return (
                      <Redirect
                        to={{
                          pathname: "/notfound",
                        }}
                        props={props}
                      />
                    );
                  }}
                />
              </Switch>
            </div>
            <div className="my-footer">
              <LastUpdate language={language} Content={Content} />
              <Footer
                language={language}
                Content={Content}
                style={{ backgroundColor: "#1F1F1F" }}
              />
            </div>
          </Suspense>
          <NotificationSystem ref="notificationSystem" />
          {needPopover ? (
            <div style={{ height: 180, width: 200 }}>
              <Popover
                id="popover-basic"
                placement="right"
                positionLeft={popoverLeft}
                positionTop={popoverTop - 30}
                title={popoverTitle}
              >
                <div style={noOverflow}>
                  <button
                    className="button is-fullwidth"
                    onMouseDown={(event) => {
                      this.onSaveButtonDown(event);
                    }}
                  >
                    <span className="icon is-small">
                      <i className="fas fa-plus"></i>
                    </span>
                    <span>Save for Later</span>
                  </button>
                  <button
                    className="button"
                    onMouseDown={(event) => {
                      this.onHelpfulButtonDown(event);
                    }}
                  >
                    <span className="icon is-small">
                      <i className="far fa-thumbs-up"></i>
                    </span>
                    <span>Helpful</span>
                  </button>
                  <button
                    className="button"
                    onMouseDown={(event) => {
                      this.onUnHelpfulButtonDown(event);
                    }}
                  >
                    <span className="icon is-small">
                      <i className="far fa-thumbs-down"></i>
                    </span>
                    <span>Unhelpful</span>
                  </button>
                </div>
              </Popover>
            </div>
          ) : (
            <div />
          )}
        </div>
      </Router>
      //</ConnectedRouter>
      //</Provider>
    );
  }
}

/*
<Route
exact
path="/Español/comparado-con-otras-pruebas-de-deteccion-temprana"
render={props => (
  <ComparePage
    mainContentStyle={mainContentStyle}
    changeLanguage={this.changeLanguage}
    Content={Content}
    deviceType={deviceType}
    savedContent={savedContent}
    {...this.props}
    {...props}
  />
)}
/>
            
<Route
exact
path="/English/compare-with-other-screening-tests"
render={props => (
  <ComparePage
    mainContentStyle={mainContentStyle}
    changeLanguage={this.changeLanguage}
    Content={Content}
    deviceType={deviceType}
    savedContent={savedContent}
    {...this.props}
    {...props}
  />
)}
/>
*/

//export default App;

/*
<Button
//type="button"
onMouseDown={this.onSaveButtonDown}
style={{ width: 200, height: 50 }}
>
<Glyphicon glyph="glyphicon glyphicon-plus" />Save for Later
</Button>
<Button
//type="button"
onMouseDown={this.onHelpfulButtonDown}
style={{ width: 100, height: 50 }}
>
<Glyphicon glyph="glyphicon glyphicon-thumbs-up" />{" "}Helpful
</Button>
<Button
//type="button"
onMouseDown={this.onUnHelpfulButtonDown}
style={{ width: 100, height: 50 }}
>
<Glyphicon glyph="glyphicon glyphicon-thumbs-down" />{" "}Unhelpful
</Button>
*/

// sticky saved badge
/*
{showSavedBadge ? (
  <Sticky enabled={true} top={80} innerZ={150}>
    <div
      className="columns is-desktop is-mobile"
      style={{ height: 0 }}
    >
      <div className="column" />
      <div className="column" />
      <div className="column" />
      <div
        className="column"
        style={{
          backgroundColor: "white",
          height: 50,
          cursor: "pointer"
        }}
      >
        <Link
          to={savedComponentContent.saved_url}
          style={{ width: "100%", textDecoration: "none" }}
          data-on="click"
          data-event-category="SavedBadge"
          data-event-action={savedComponentContent.saved_url}
        >
          <Badge>{savedComponentContent.saved_label + ": " + savedContent.filter((item, index)=> {return item.category=="saved"}).length}</Badge>
        </Link>
      </div>
    </div>
  </Sticky>
) : (
  <div />
)}
*/

// buttons in a row
/*
<div className="row" style={noOverflow}>
<div className="col c4">
  <button type="button" onMouseDown={this.onSaveButtonDown} style={{width: "100%", height: 50}}>
    Save for Later
  </button>
</div>
<div className="col c4">
  <button type="button" onMouseDown={this.onHelpfulButtonDown} style={{width: "100%", height: 50}}>
    Helpful
  </button>
</div>
<div className="col c4">
  <button type="button" onMouseDown={this.onUnHelpfulButtonDown} style={{width: "100%", height: 50}}>
    Unhelpful
  </button>
</div>
</div>
*/
